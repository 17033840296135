import React  from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

export const swal={

    warning:(data)=>{
        var popup ="";
        
            popup = <SweetAlert
                        warning
                        showCancel
                        confirmBtnText={data.confirmBtnText}
                        confirmBtnBsStyle="btn theme-button"
                        cancelBtnBsStyle="default"
                        title={data.title}
                        onConfirm={data.onConfirm}
                        onCancel={data.onCancel}
                    >{data.subtitle}</SweetAlert>
        
        return popup           
    },
    success:(data)=>{
        var popup ="";
         
            popup = <SweetAlert
                     success 
                     title={data.title} 
                     onConfirm={data.onConfirm}>
                       {data.subtitle}
                    </SweetAlert>
         
        return popup
        
    },
    failed:(data)=>{
        var popup ="";
         
            popup = <SweetAlert
                     danger 
                     btnSize='lg'
                     title={data.title} 
                     onConfirm={data.onConfirm}>
                       {data.subtitle}
                       
                    </SweetAlert>
         
        return popup
        
    }
    
}