import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { UserDetails } from "../../actions/AuthAction/Myprofile";

export const MyProfileDetailsApi = form => {
  return (dispatch, getState) => {
    dispatch(UserDetails.UserDetailsFetching());
    return _callApi(
      END_POINT.AUTH.MYPROFILE.END_POINT,
      END_POINT.AUTH.MYPROFILE.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(UserDetails.UserDetailsFetchingFailed({result,message}));
        return res.data;
      } else {
        dispatch(UserDetails.UserDetailsFetched({result}));
        return res.data;
      }
    });
  };
};
