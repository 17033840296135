export const ACTIONS = {

    COMMON_ACTION:{
        DELETE:{
            DELETING:"DELETING[DELETE]",
            DELETED:"DELETED[DELETE]",
            DELETING_FAILED: "DELETING_FAILED[DELETE]",
        },
        ADD:{
            ADDING:"ADDING[ADD]",
            ADDED:"ADDED[ADD]",
            ADDING_FAILED: "ADDING_FAILED[DELETE]",
        },
        UPDATE:{
            UPDATING:"UPDATING[UPDATE]",
            UPDATED:"UPDATED[UPDATE]",
            UPDATING_FAILED: "UPDATING_FAILED[UPDATE]",
        },
        
    },
    AUTH_ACTION:{
        SIGNIN:{
            SIGNIN:"SIGNIN[SIGNIN]",
            SIGNIN_FAILED:"SIGNIN_FAILED[SIGNIN]",
            SIGNEDIN: "SIGNEDIN[SIGNIN]"
        },
        FORGET:{
            FORGETIN:"FORGETIN[FORGETIN]",
            FORGET_FAILED:"FORGET_FAILED[FORGETIN]",
            FORGETED: "FORGETED[FORGETIN]"
        }
    },
    QUESTION_ACTION:{
        GET_QUESTION:{
            QUESTION_FETCHING:"FETCHING[QUESTION_FETCHING]",
            QUESTION_FETCHING_FAILED:"FAILED[QUESTION_FETCHING_FAILED]",
            QUESTION_FETCHED:"FETCHED[QUESTION_FETCHED]"
        }
    },
    CONTENT_ACTION:{
        GET_CONTENT:{
            CONTENT_FETCHING:"FETCHING[CONTENT_FETCHING]",
            CONTENT_FETCHING_FAILED:"FAILED[CONTENT_FETCHING_FAILED]",
            CONTENT_FETCHED:"FETCHED[CONTENT_FETCHED]"
        }
    },
    SEMINAR_ACTION:{
        GET_SEMINAR:{
            SEMINAR_FETCHING:"FETCHING[SEMINAR_FETCHING]",
            SEMINAR_FETCHING_FAILED:"FAILED[SEMINAR_FETCHING_FAILED]",
            SEMINAR_FETCHED:"FETCHED[SEMINAR_FETCHED]"
        }
    }

}