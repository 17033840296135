import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import payments from '../../../../assets/images/payments.png'
import {LoadingComponent} from '../../../components/commonComponents/index';


export default class Footer extends Component {

    constructor(props){
        super(props);
        this.state= {
           isLoading:true        }
    }

    componentDidMount() {
        
        setTimeout(()=>this.setState({isLoading:false}),2000)
    }

    render() {
        let loader = <LoadingComponent/>
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12 footer">
                        <div className="footer_txt_copy">
                        </div>
                    </div>
                </div>
            </div>
            
            
        )
    }
}
