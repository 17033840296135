import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import Profileicon from '../../../../assets/images/Profileicon.png'
import { connect } from "react-redux";
import { CONSTANTS } from "../../../../constants/constants";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import {InputBox,SelectBox,LoadingComponent,MaterialUIPickers} from '../../commonComponents/index'
import { PaymentApi } from "../../../../store/api-actions/payment/PaymentApi";
import { MyProfileDetailsApi } from "../../../../store/api-actions/auth/myprofileDetailsApi";
import { uploadFile } from 'react-s3';
import { CONFIG } from "../../../../constants/config";
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';
import moment from 'moment'
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { END_POINT } from "../../../../constants/apiEndPoints";
import Axios from "axios";



const mapDispatchToProps = dispatch => {
  return {
    payment: form => dispatch(PaymentApi(form)),
    MyProfileDetails: form => dispatch(MyProfileDetailsApi(form))
  };
};

const mapStateToProps = state => {
  return {
      
  };
};

class Payment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading:false,
      isWarningConfirMsg:false,
      isSuccessConfirMsg:false,
      isFailedConfirMsg:false,
      _id:"",
      message: null,
      title:"",
      formFields: {},
      errors: {},
      messageElement: false,
      success: false,
      file:Profileicon,
      s3file:"",
      isLoader:false,
      image:"",
      fromDate:new Date(),
      isSameAddress:false
    };
  }


  componentDidMount(){
    this.setState({isLoading:true});
    let user = JSON.parse(localStorage.getItem("dmvUser") || "");
    let header = user
    ? {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
        accessToken: user.accessToken
      }
    : { "Content-Type": "application/json" };
    Axios
      .get(`${END_POINT.BASE_URL}payment`,
      {
        params:{seminarId:this.props.match.params.id},
        headers: header
      })
      .then(res => {
        console.log("@@@@@@@@&&&&&&&&&",res)
        if(res.data.code==200){
          window.location = res.data.result
        }

        if(res.data.message=="Unauthorized access "){
          console.log("*******&&&&&&&&&")
          window.location = END_POINT.APP_BASE;
          localStorage.removeItem("dmvUser");

        }
        this.setState({isLoading:false});
        return res;
      });
  
  }

  handlePayment = (values, formikBag)=> {
    console.log("==0000000000",values)
    this.setState({isLoading:true});
    values.seminarId = this.props.match.params.id
 
    this.props.payment(values).then(res=>{

        if(res.status && Object.keys(res.result).length  > 0){
            this.setState({isSuccessConfirMsg:true,isLoading:false,message:MESSAGE.PAYMENT,title:MESSAGE.SUCCESS_TITLE});
          //this.props.history.push(`/portal/seminar/${this.props.match.params.id}`)
        }else{

            this.setState({isLoading:false,message:res.message});
        }
    })
  }

  render() {

            var conm = "";
            var loader = ""
            var obj = {
                title:this.state.title,
                onConfirm:()=>{
                this.setState({isFailedConfirMsg:false,isSuccessConfirMsg:false});
                this.props.history.push(`/portal/dashboard`)
                },
                subtitle:this.state.message
            }

            if(this.state.isFailedConfirMsg){
                conm = swal.failed(obj);
            }
            
            if(this.state.isSuccessConfirMsg){
                conm = swal.success(obj);
            }
            if(this.state.isLoading){
                loader = <LoadingComponent/>
            }
            CONSTANTS.AuthDetails =JSON.parse(localStorage.getItem("dmvUser"))

    return (
        <>
        {loader}
        {conm}
        <Formik
              enableReinitialize={true}
              initialValues={{
                firstName:this.state.isSameAddress?(CONSTANTS.AuthDetails.firstName):'',
                middleName:this.state.isSameAddress?(CONSTANTS.AuthDetails.middleName):'',
                lastName:this.state.isSameAddress?(CONSTANTS.AuthDetails.lastName):'',
                address1:this.state.isSameAddress?(CONSTANTS.AuthDetails.address1):'',
                address2:this.state.isSameAddress?(CONSTANTS.AuthDetails.address2):'',
                city:this.state.isSameAddress?(CONSTANTS.AuthDetails.city):'',
                state:this.state.isSameAddress?(CONSTANTS.AuthDetails.state):'',
                zipCode:this.state.isSameAddress?(CONSTANTS.AuthDetails.zipCode):'',
                email:this.state.isSameAddress?(CONSTANTS.AuthDetails.email):'',
                phoneNumber:this.state.isSameAddress?(CONSTANTS.AuthDetails.phoneNumber):'',
                ccNumber:'',
                expirationMonth:'',
                expirationYear:'',
                cvvNumber:''
              }}
              onSubmit={this.handlePayment}
              validationSchema={VALIDATORS.PAYMENT}
              render={({ handleSubmit, errors, touched, values, handleChange,setFieldValue })=>(
                
                <form onSubmit={handleSubmit}>
                    
                                
                                <div class="row register-form inner-payment-div">
                                        <div className="col-md-12 text-center">
                                            {(this.state.message)?(<span className="text-danger" >
                                                {this.state.message}
                                                </span>):("")}
                                        </div>
                                        <div className="col-md-12">
                                            <h6>Billing Information</h6>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" onChange={()=>{
                                                    if(this.state.isSameAddress)
                                                    this.setState({isSameAddress:false})
                                                    else
                                                    this.setState({isSameAddress:true})
                                                }} checked={this.state.isSameAddress} className="custom-control-input" id="customCheck1"/>
                                                <label className="custom-control-label" for="customCheck1">Billig address Same as the mailing address</label>
                                            </div>
                                        </div>
                                        <InputBox
                                            formWrapClass = "form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* First Name"
                                            name = "firstName"
                                            handleChange={handleChange}
                                            value={values.firstName}
                                            errorClass = "text-danger"
                                            errorText = { touched.firstName && errors.firstName }
                                        />  
                                        <InputBox
                                            formWrapClass = "form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="Middle Name"
                                            name = "middleName"
                                            handleChange={handleChange}
                                            value={values.middleName}
                                            errorClass = "text-danger"
                                            errorText = { touched.middleName && errors.middleName }
                                        />  
                                        <InputBox
                                            formWrapClass = "form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* Last Name"
                                            name = "lastName"
                                            handleChange={handleChange}
                                            value={values.lastName}
                                            errorClass = "text-danger"
                                            errorText = { touched.lastName && errors.lastName }
                                        />  
                                        <InputBox
                                            formWrapClass = "form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* Address 1"
                                            name = "address1"
                                            handleChange={handleChange}
                                            value={values.address1}
                                            errorClass = "text-danger"
                                            errorText = { touched.address1 && errors.address1 }
                                        />  
                                        <InputBox
                                            formWrapClass = "form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="Address 2"
                                            name = "address2"
                                            handleChange={handleChange}
                                            value={values.address1}
                                            errorClass = "text-danger"
                                            errorText = { touched.address1 && errors.address1 }
                                        />  
                                        <InputBox
                                            formWrapClass = "form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* City"
                                            name = "city"
                                            handleChange={handleChange}
                                            value={values.city}
                                            errorClass = "text-danger"
                                            errorText = { touched.city && errors.city }
                                        />  
                                        <SelectBox
                                            formWrapClass = "form-group col-md-6"
                                            selectClass = "form-control"
                                            placeholder = "* State"
                                            name = "state"
                                            handleChange={handleChange}
                                            options = {CONSTANTS.STATE}
                                            selectValue={values.state}
                                            errorClass = "text-danger"
                                            errorText = { touched.state && errors.state }
                                        /> 
                                        <InputBox
                                            formWrapClass = "form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* Zip Code"
                                            name = "zipCode"
                                            handleChange={handleChange}
                                            value={values.zipCode}
                                            errorClass = "text-danger"
                                            errorText = { touched.zipCode && errors.zipCode }
                                        />  
                                        
                                        <InputBox
                                            formWrapClass = "form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* Phone No."
                                            name = "phoneNumber"
                                            handleChange={handleChange}
                                            value={values.phoneNumber}
                                            errorClass = "text-danger"
                                            errorText = { touched.phoneNumber && errors.phoneNumber }
                                        />  
                                        <InputBox
                                            formWrapClass = "form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* Email"
                                            name = "email"
                                            handleChange={handleChange}
                                            value={values.email}
                                            errorClass = "text-danger"
                                            errorText = { touched.email && errors.email }
                                        /> 
                                        <div className="col-md-12">
                                            <h6>Payment Details</h6>
                                        </div> 
                                        <InputBox
                                            formWrapClass = "form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* Credit Card Number"
                                            name = "ccNumber"
                                            handleChange={handleChange}
                                            value={values.ccNumber}
                                            errorClass = "text-danger"
                                            errorText = { touched.ccNumber && errors.ccNumber }
                                        /> 
                                        <SelectBox
                                            formWrapClass = "form-group col-md-6"
                                            selectClass = "form-control"
                                            placeholder = "* Card Expiration Month"
                                            name = "expirationMonth"
                                            handleChange={handleChange}
                                            options = {CONSTANTS.MONTH}
                                            errorClass = "text-danger"
                                            errorText = { touched.expirationMonth && errors.expirationMonth }
                                        /> 
                                        <SelectBox
                                            formWrapClass = "form-group col-md-6"
                                            selectClass = "form-control"
                                            placeholder = "* Card Expiration Year"
                                            name = "expirationYear"
                                            handleChange={handleChange}
                                            options = {CONSTANTS.YEAR}
                                            errorClass = "text-danger"
                                            errorText = { touched.expirationYear && errors.expirationYear }
                                        /> 
                                        <InputBox
                                            formWrapClass = "form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* Credit Card Security Code"
                                            name = "cvvNumber"
                                            handleChange={handleChange}
                                            value={values.cvvNumber}
                                            errorClass = "text-danger"
                                            errorText = { touched.cvvNumber && errors.cvvNumber }
                                        /> 
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-12 text-center">
                                            <button type="submit" className="btn btn-info" >Submit</button>
                                        </div>
                                </div>
                                
                            
                </form>
                )}
                />
      </>
    );
  }
}

export default Payment  = connect(mapStateToProps, mapDispatchToProps)(Payment);
