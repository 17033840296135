import React, { Component } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

export default class LoadingComponent extends Component {
    //other logic
    render() {
        return (
            <div className="custom-loader">
                <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={3000000} //3 secs
                />
            </div>
            
        );
    }
}