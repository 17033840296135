import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { Question } from "../../actions/QuestionAction/GetQuestion";

export const QuestionApi = form => {
  return (dispatch, getState) => {
    dispatch(Question.QuestionFetching());
    return _callApi(
      END_POINT.QUESTION.GET_QUESTION.END_POINT,
      END_POINT.QUESTION.GET_QUESTION.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(Question.QuestionFetchingFailed({result,message}));
        return res.data;
      } else {
        dispatch(Question.QuestionFetched({result}));
        return res.data;
      }
    });
  };
};
