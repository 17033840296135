import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import BillingInformation from '../../authComponents/billingInformtion/BillingInformation'
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { Formik } from "formik";
import { InputBox, SelectBox, LoadingComponent, MaterialUIPickers } from '../../commonComponents/index'
import { CONSTANTS } from "../../../../constants/constants";
import { connect } from "react-redux";
import { SeminarPaymentApi } from "../../../../store/api-actions/seminarPayment/seminarPayment";
import Axios from "axios";
import { END_POINT } from "../../../../constants/apiEndPoints";
import { Button, Modal } from "react-bootstrap";
import { CheckCoupenApi } from "../../../../store/api-actions/auth/checkCoupen";


const mapDispatchToProps = dispatch => {
  return {
    SeminarPayment: form => dispatch(SeminarPaymentApi(form)),
    CheckCoupen: form => dispatch(CheckCoupenApi(form))
  };
};

const mapStateToProps = state => {
  return {
    seminarState: state.seminar
  };
};

class Seminar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isWarningConfirMsg: false,
      isSuccessConfirMsg: false,
      isFailedConfirMsg: false,
      _id: "",
      message: null,
      title: "",
      formFields: {},
      errors: {},
      messageElement: false,
      success: false,
      file: "",
      s3file: "",
      isLoader: false,
      image: "",
      fromDate: new Date(),
      isSameAddress: false,
      coupenCode: "",
      coupen: null,
      coupenModal: false,
      activeSeminarId: false,
      isCoupenApiCalling:false
    };
  }

  componentDidMount() {
    this.props.SeminarPayment().then((result) => {
      // console.log("=====]]]",list)
      // list.find(ans => (ans.seminarId === "seminar-1" && ans.isPass === true)
    })
  }

  checkvalidation(list, seminarId) {
    if (list.find(ans => (ans.seminarId === seminarId && ans.isPass === true))) {
      return "/portal/dashboard/certificate/" + seminarId
    }
    if (list.find(ans => (ans.seminarId === seminarId))) {
      return "/portal/seminar/" + seminarId;
    }
    return false;
  }

  checkCoupen = (e) => {
    this.setState({ coupenCode: e.target.value,isCoupenApiCalling:true });
    this.props.CheckCoupen({ coupenCode: e.target.value }).then(res => {
      if (res?.result?.discAmount) {
        this.setState({ coupen: res?.result,isCoupenApiCalling:false });
      } else {
        this.setState({ coupen: null,isCoupenApiCalling:false });
      }
    })
  }
  _openCoupenModal(list, seminarId) {
     this.setState({coupenModal:true,activeSeminarId:seminarId})
     
  }

  _closeCoupenModal() {
    this.setState({coupenModal:false,coupen: null,isCoupenApiCalling:false,coupenCode:""})
 }

  _callpaymanet(list, seminarId) {
    if (list.find(ans => (ans.seminarId === seminarId && ans.isPass === true))) {
      return false
    }
    if (list.find(ans => (ans.seminarId === seminarId))) {
      return false;
    }
    this.setState({ isLoading: true });
    let user = JSON.parse(localStorage.getItem("dmvUser") || "");
    let header = user
      ? {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
        accessToken: user.accessToken
      }
      : { "Content-Type": "application/json" };
    Axios
      .get(`${END_POINT.BASE_URL}payment`,
        {
          params: { seminarId: seminarId,coupenCode:this.state.coupenCode },
          headers: header
        })
      .then(res => {
        console.log("@@@@@@@@&&&&&&&&&", res)
        if (res.data.code == 200) {
          window.location = res.data.result
        }

        if (res.data.message == "Unauthorized access ") {
          console.log("*******&&&&&&&&&")
          window.location = END_POINT.APP_BASE;
          localStorage.removeItem("dmvUser");

        }
        this.setState({ isLoading: false });
        return res;
      });
  }

  render() {
    let { list } = this.props.seminarState
    let loader = "";
    if (this.state.isLoading) {
      loader = <LoadingComponent />
    }
    return (
      <>
        {loader}

        <Modal
          show={this.state.coupenModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Body>
            <InputBox
              formWrapClass="form-group col-md-12"
              inputClass="form-control"
              labelText="Coupon code"
              type="text"
              placeholder="Coupon Codes"
              name="coupenCode"
              handleChange={(e) => {
                this.checkCoupen(e)
              }}

              errorClass={
                this.state.coupenCode && !this.state.isCoupenApiCalling && !this.state?.coupen?.discAmount ? "text-danger" : (
                  this.state.coupenCode && !this.state.isCoupenApiCalling && this.state?.coupen?.discAmount ? "text-success" : ""
                )
              }
              errorText={
                this.state.coupenCode && !this.state.isCoupenApiCalling && !this.state?.coupen?.discAmount ? "Invalid Coupon code" : (
                  this.state.coupenCode && !this.state.isCoupenApiCalling && this.state?.coupen?.discAmount ? "Coupon applied successfully" : ""
                )
              }

            />
            <div className="form-group col-md-6">
              {
                this.state.coupenCode && this.state?.coupen?.discAmount && (
                  <>
                    <span className="text-success">Package Amount: ${this.state?.coupen.pkgAmount}</span><br />
                    <span className="text-success">Discount: -${this.state?.coupen.discAmount}</span> <br />
                    <span className="text-success">Total Amount: ${this.state?.coupen.paybleAmount}</span>
                  </>
                )
              }
            </div>


            <div className="text-center">
              <button onClick={()=>this._closeCoupenModal()} type="button" className="btn btn-default">Close</button>
              &nbsp;
              &nbsp;
              <Button onClick={()=>{this._closeCoupenModal();this._callpaymanet(list,this.state.activeSeminarId);}} >Continue</Button>


            </div>
          </Modal.Body>

        </Modal>

        <div className="">
          <div className={list.find(ans => (ans.seminarId === "seminar-1" && ans.isPass === true)) ? "center-div form-group max-auto complete-lesson" : "center-div form-group max-auto"}>

            {this.checkvalidation(list, "seminar-1") ? <Link to={this.checkvalidation(list, "seminar-1")}
            >SEMINAR 1</Link> : <a href="javascript:void(0)"
              onClick={() => { this._openCoupenModal(list, "seminar-1") }}
            >SEMINAR 1</a>}
          </div>
          <div className={list.find(ans => (ans.seminarId === "seminar-2" && ans.isPass === true)) ? "center-div form-group max-auto complete-lesson" : "center-div form-group max-auto"}>
            {this.checkvalidation(list, "seminar-2") ? <Link to={this.checkvalidation(list, "seminar-2")}
            >SEMINAR 2</Link> : <a href="javascript:void(0)"
              onClick={() => { this._openCoupenModal(list, "seminar-2") }}
            >SEMINAR 2</a>}
          </div>
          <div className={list.find(ans => (ans.seminarId === "seminar-3" && ans.isPass === true)) ? "center-div form-group max-auto complete-lesson" : "center-div form-group max-auto"}>
            {this.checkvalidation(list, "seminar-3") ? <Link to={this.checkvalidation(list, "seminar-3")}
            >SEMINAR 3</Link> : <a href="javascript:void(0)"
              onClick={() => { this._openCoupenModal(list, "seminar-3") }}
            >SEMINAR 3</a>}
          </div>
        </div>
      </>
    );
  }
}

export default Seminar = connect(mapStateToProps, mapDispatchToProps)(Seminar);
