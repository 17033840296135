import React from 'react'

// import { countryOptions } from '../common'

const SelectBox = ({ formWrapClass,labelClass,labelText, selectClass, name, options, handleChange,errorClass,errorText,selectValue,placeholder }) => {


    const option = options.map((obj) =>
        <option key={obj.key} selected = {selectValue==obj.value?"selected":""} value={obj.value}>{obj.text}</option> 
    );
    return (
        <div className={formWrapClass}>
            <label className={labelClass}>{labelText}</label>
            <select onChange={handleChange} name={name} className={selectClass}>
                <option selected disabled >{placeholder?placeholder:"* select"}</option>
                {option}
            </select>
             <span className={errorClass}>{errorText}</span> 
        </div>
    )
}

export default SelectBox

