import React, { Component } from "react";
import { NavLink } from "react-bootstrap";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import Profileicon from '../../../../assets/images/Profileicon.png'
import { connect } from "react-redux";
import { CONSTANTS } from "../../../../constants/constants";
import { END_POINT } from "../../../../constants/apiEndPoints";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import {LoadingComponent,MaterialUIPickers} from '../../commonComponents/index'
import CustomerInformation from '../customerInformation/CustomerInformation'
import Login from '../login/Login'
import ForgotPassword from '../forgotPassword/ForgotPassword'
import BillingInformation from '../billingInformtion/BillingInformation'
import { CompleteProfileApi } from "../../../../store/api-actions/auth/completeProfileApi";
import { MyProfileDetailsApi } from "../../../../store/api-actions/auth/myprofileDetailsApi";
import { uploadFile } from 'react-s3';
import { CONFIG } from "../../../../constants/config";
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';
import moment from 'moment'
import { Switch, Route,Redirect} from "react-router-dom";
// import mainLogo from'../../../../assets/images/Advantage-Circle-Logo.png';


const mapDispatchToProps = dispatch => {
  return {
    CompleteProfile: form => dispatch(CompleteProfileApi(form)),
    MyProfileDetails: form => dispatch(MyProfileDetailsApi(form))
  };
};

const mapStateToProps = state => {
  return {
      
  };
};

class MainAuth extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading:false,
      isWarningConfirMsg:false,
      isSuccessConfirMsg:false,
      isFailedConfirMsg:false,
      _id:"",
      message: null,
      title:"",
      formFields: {},
      errors: {},
      messageElement: false,
      success: false,
      file:Profileicon,
      s3file:"",
      isLoader:false,
      image:""
    };
  }

  handleSignUp = (values, formikBag)=> {
    this.setState({isLoading:true});
    if(this.state.s3file){
      uploadFile(this.state.s3file, CONFIG.S3_BUCKET_DETAILS)
        .then(data => {
            values.image = data.key;
            this.setState({image:data.key});
        })
        .catch(err => console.error(err))
    }
    values.accessToken = JSON.parse(localStorage.getItem('styleUser')).accessToken;
    
    values.dob = moment(values.dob, "YYYY/M/D").valueOf();
    this.props.CompleteProfile(values).then(res=>{
        if(res.status && Object.keys(res.result).length  > 0){
          let data = JSON.parse(localStorage.getItem('styleUser'))
          localStorage.removeItem('styleUser')
          localStorage.setItem('styleUserToken', JSON.stringify(data))
          this.props.MyProfileDetails().then(res1=>{})
          this.setState({isSuccessConfirMsg:true,isLoading:false,message:MESSAGE.CHANGE_PASSWORD,title:MESSAGE.SUCCESS_TITLE});
          //history.push(`/`)
        }else{
            this.setState({isFailedConfirMsg:true,isLoading:false,message:res.message,title:MESSAGE.FAILED_TITLE});
        }
         
    })
     
  }

  render() {

            var conm = "";
            var loader = ""
            var obj = {
                title:this.state.title,
                onConfirm:()=>{
                this.setState({isFailedConfirMsg:false,isSuccessConfirMsg:false});
                this.props.history.push(`/`)
                },
                subtitle:this.state.message
            }

            if(this.state.isFailedConfirMsg){
                conm = swal.failed(obj);
            }
            
            if(this.state.isSuccessConfirMsg){
                conm = swal.success(obj);
            }
            if(this.state.isLoading){
                loader = <LoadingComponent/>
            }
            //  console.log("YTREEWQWERFDFTRDTDTRDRTDTRDT",CONSTANTS.AuthDetails.status)
    return (
        <div className="container register">
        <div className="row">
            <div className="col-md-3 register-left">
                <img  src="https://instructorseminar.com/api/uploads/logo2.png" alt=""/>
                <h3>Welcome to</h3>
                <p>Driving School 
                  Association of California
                </p>
                 <Link to="/auth/login">
                  <input type="submit" name="" value="Login"/>
                </Link>
                <br/>                
                <Link to="/auth/forgetPassword" className="color-wh" >
                Forgot Password
                </Link>
            </div>
            <div className="col-md-9 register-right">
                <ul className="nav nav-tabs nav-justified" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Information</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Payment</a>
                    </li>
                </ul>
                <Switch>
                    <Route
                        exact={true}
                        path="/auth/"
                        render={props => {
                            if (CONSTANTS.AuthDetails && CONSTANTS.AuthDetails.status == 0) {
                              return <Redirect from="/auth/" to="/auth/billing-information/" />;
                            }else if(CONSTANTS.AuthDetails && CONSTANTS.AuthDetails.status == 1){
                              return <Redirect from="/auth/" to="/portal/" />;
                            } else {
                              return <Redirect from="/auth/" to="/auth/customer-information/" />;
                            }
                        }
                        }
                    />
                    {/* <Route  path="/auth/" component={CustomerInformation} /> */}
                    <Route exact path="/auth/login" component={Login} />
                    <Route exact path="/auth/forgetPassword" component={ForgotPassword} />
                    <Route  path="/auth/customer-information" component={CustomerInformation} />
                    <Route  path="/auth/billing-information" component={BillingInformation} />
                </Switch>
            </div>
        </div>
        </div>
      
    );
  }
}

export default MainAuth  = connect(mapStateToProps, mapDispatchToProps)(MainAuth);
