import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import Profileicon from '../../../../assets/images/Profileicon.png'
import { connect } from "react-redux";
import { CONSTANTS } from "../../../../constants/constants";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import { InputBox, SelectBox, LoadingComponent, MaterialUIPickers } from '../../commonComponents/index'
import { SignUpApi } from "../../../../store/api-actions/auth/signUpApi";
import { CONFIG } from "../../../../constants/config";
import { swal } from '../../../../constants/confirm-alert';
import { MESSAGE } from '../../../../constants/confirmMessage';
import moment from 'moment'
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { CheckCoupenApi } from "../../../../store/api-actions/auth/checkCoupen";



const mapDispatchToProps = dispatch => {
    return {
        SignUp: form => dispatch(SignUpApi(form)),
        CheckCoupen: form => dispatch(CheckCoupenApi(form))
    };
};

const mapStateToProps = state => {
    return {

    };
};

class CustomerInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isWarningConfirMsg: false,
            isSuccessConfirMsg: false,
            isFailedConfirMsg: false,
            _id: "",
            message: null,
            title: "",
            formFields: {},
            errors: {},
            messageElement: false,
            success: false,
            file: Profileicon,
            s3file: "",
            isLoader: false,
            image: "",
            coupen: null
        };
    }

    handleSignUp = (values, formikBag) => {
        console.log("=====000000000", values)
        this.setState({ isLoading: true });
        values.dob = moment(values.dob, "YYYY/M/D").valueOf();
        this.props.SignUp(values).then(res => {
            if (res.status && Object.keys(res.result).length > 0) {
                let payurl = res.result.payurl;
                delete res.result.payurl;
                localStorage.setItem('dmvUser', JSON.stringify(res.result))
                this.setState({ isLoading: false });
                //   this.props.history.push(`/auth/billing-information`)
                window.location = payurl
            } else {
                this.setState({ isFailedConfirMsg: true, isLoading: false, message: res.message, title: MESSAGE.FAILED_TITLE });
            }

        })

    }

    checkCoupen = (e) => {
        this.setState({ isCoupenApiCalling:true });
        this.props.CheckCoupen({ coupenCode: e.target.value }).then(res => {
            if (res?.result?.discAmount) {
                this.setState({ coupen: res?.result,isCoupenApiCalling:false  });
            }else{
                this.setState({ coupen: null,isCoupenApiCalling:false  });
            }
        })
    }

    // fromDate = (date)=>{
    //     this.setState({fromDate:date})
    // }

    render() {

        var conm = "";
        var loader = ""
        var obj = {
            title: this.state.title,
            onConfirm: () => {
                this.setState({ isFailedConfirMsg: false, isSuccessConfirMsg: false });
                this.props.history.push(`/`)
            },
            subtitle: this.state.message
        }

        if (this.state.isFailedConfirMsg) {
            conm = swal.failed(obj);
        }

        if (this.state.isSuccessConfirMsg) {
            conm = swal.success(obj);
        }
        if (this.state.isLoading) {
            loader = <LoadingComponent />
        }

        return (
            <>
                {loader}
                {conm}
                <Formik
                    initialValues={{
                        userName: '',
                        password: '',
                        firstName: '',
                        middleName: '',
                        lastName: '',
                        address1: '',
                        address2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        dob: '',
                        email: '',
                        phoneNumber: '',
                        schoolName: '',
                        instructorLicense: '',
                        schoolOperator: '',
                        isCoupenApiCalling:false
                    }}
                    onSubmit={this.handleSignUp}
                    validationSchema={VALIDATORS.SIGNUP}
                    render={({ handleSubmit, errors, touched, values, handleChange, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    {/* <h3 >We are upgrading our system and will be back on Monday 17 oct 2022. Thank you for your patience.</h3><br/> */}
                                    <h3 class="register-heading">
                                        {/* <span className="font-size-msg">We are upgrading our system and will be back on Monday 17 oct 2022. <br/> Thank you for your patience.</span> <br/> */}

                                        Customer Information
                                    </h3>
                                    <div class="row register-form">
                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* User Name"
                                            name="userName"
                                            handleChange={handleChange}
                                            value={values.userName}
                                            errorClass="text-danger"
                                            errorText={touched.userName && errors.userName}
                                        />
                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="password"
                                            placeholder="* Password"
                                            name="password"
                                            handleChange={handleChange}
                                            value={values.password}
                                            errorClass="text-danger"
                                            errorText={touched.password && errors.password}
                                        />
                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* First Name"
                                            name="firstName"
                                            handleChange={handleChange}
                                            value={values.firstName}
                                            errorClass="text-danger"
                                            errorText={touched.firstName && errors.firstName}
                                        />
                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="Middle Name"
                                            name="middleName"
                                            handleChange={handleChange}
                                            value={values.middleName}
                                            errorClass="text-danger"
                                            errorText={touched.middleName && errors.middleName}
                                        />
                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* Last Name"
                                            name="lastName"
                                            handleChange={handleChange}
                                            value={values.lastName}
                                            errorClass="text-danger"
                                            errorText={touched.lastName && errors.lastName}
                                        />
                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* Address 1"
                                            name="address1"
                                            handleChange={handleChange}
                                            value={values.address1}
                                            errorClass="text-danger"
                                            errorText={touched.address1 && errors.address1}
                                        />
                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="Address 2"
                                            name="address2"
                                            handleChange={handleChange}
                                            value={values.address2}
                                            errorClass="text-danger"
                                            errorText={touched.address2 && errors.address2}
                                        />
                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* City"
                                            name="city"
                                            handleChange={handleChange}
                                            value={values.city}
                                            errorClass="text-danger"
                                            errorText={touched.city && errors.city}
                                        />
                                        <SelectBox
                                            formWrapClass="form-group col-md-6"
                                            selectClass="form-control"
                                            placeholder="* State"
                                            name="state"
                                            handleChange={handleChange}
                                            options={CONSTANTS.STATE}
                                            errorClass="text-danger"
                                            errorText={touched.state && errors.state}
                                        />
                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* Zip Code"
                                            name="zipCode"
                                            handleChange={handleChange}
                                            value={values.zipCode}
                                            errorClass="text-danger"
                                            errorText={touched.zipCode && errors.zipCode}
                                        />
                                        <div className="col-md-6 dob">
                                            <MaterialUIPickers
                                                placeholder="* Dob (MM/DD/YYYY)"
                                                margin="normal"
                                                id="dob"
                                                name="dob"
                                                maxDate={new Date()}
                                                value={values.dob}
                                                setFieldValue={(date) => {
                                                    setFieldValue('dob', date)
                                                    //this.dob(date)
                                                }}
                                                errorClass="text-danger"
                                                errorText={touched.dob && errors.dob}
                                            />
                                        </div>

                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* Phone No."
                                            name="phoneNumber"
                                            handleChange={handleChange}
                                            value={values.phoneNumber}
                                            errorClass="text-danger"
                                            errorText={touched.phoneNumber && errors.phoneNumber}
                                        />
                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="* Email"
                                            name="email"
                                            handleChange={handleChange}
                                            value={values.email}
                                            errorClass="text-danger"
                                            errorText={touched.email && errors.email}
                                        />

                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="Driving School Name"
                                            name="schoolName"
                                            handleChange={handleChange}
                                            value={values.schoolName}
                                            errorClass="text-danger"
                                            errorText={touched.schoolName && errors.schoolName}
                                        />
                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="Driving Instructor License#"
                                            name="instructorLicense"
                                            handleChange={handleChange}
                                            value={values.instructorLicense}
                                            errorClass="text-danger"
                                            errorText={touched.instructorLicense && errors.instructorLicense}
                                        />
                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="School Operator#"
                                            name="schoolOperator"
                                            handleChange={handleChange}
                                            value={values.schoolOperator}
                                            errorClass="text-danger"
                                            errorText={touched.schoolOperator && errors.schoolOperator}
                                        />
                                        <InputBox
                                            formWrapClass="form-group col-md-6"
                                            inputClass="form-control"
                                            type="text"
                                            placeholder="Coupon Codes"
                                            name="coupenCode"
                                            handleChange={(e) => {
                                                handleChange(e);
                                                this.checkCoupen(e)
                                            }}
                                            value={values.coupenCode}
                                            errorClass={
                                                values.coupenCode && !this.state.isCoupenApiCalling && !this.state?.coupen?.discAmount?"text-danger":(
                                                    values.coupenCode && !this.state.isCoupenApiCalling && this.state?.coupen?.discAmount?"text-success":""
                                                )
                                            }
                                            errorText={
                                                values.coupenCode && !this.state.isCoupenApiCalling && !this.state?.coupen?.discAmount?"Invalid Coupon code":(
                                                    values.coupenCode && !this.state.isCoupenApiCalling && this.state?.coupen?.discAmount?"Coupon applied successfully":""
                                                )
                                            }

                                        />
                                        <div className="form-group col-md-6">
                                            {
                                                values.coupenCode && this.state?.coupen?.discAmount && (
                                                    <>
                                                        <span className="text-success">Package Amount: ${this.state?.coupen.pkgAmount}</span><br/>
                                                        <span className="text-success">Discount: -${this.state?.coupen.discAmount}</span> <br/>
                                                        <span className="text-success">Total Amount: ${this.state?.coupen.paybleAmount}</span>
                                                    </>
                                                )
                                            }
                                        </div>
                                        {/* {
                                            values.coupenCode && !this.state?.coupen?.discAmount && (<div className="col-md-6" >
                                                <span className="text-danger">Invalid coupen code</span>
                                            </div>)
                                        }
                                        {
                                            values.coupenCode && this.state?.coupen?.discAmount && (<div className="col-md-6" >
                                               <span className="text-success">Coupen applied successfully</span>
                                            </div>)
                                        } */}



                                        <div className="col-md-12">
                                            <button type="submit" className="btnRegister" >Register</button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </form>
                    )}
                />
            </>
        );
    }
}

export default CustomerInformation = connect(mapStateToProps, mapDispatchToProps)(CustomerInformation);
