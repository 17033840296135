import React, { Component } from "react";
import { Switch, Route,Link} from "react-router-dom";
import BillingInformation from '../../authComponents/billingInformtion/BillingInformation'
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { Formik } from "formik";
import {InputBox,SelectBox,LoadingComponent,MaterialUIPickers} from '../../commonComponents/index'
import { CONSTANTS } from "../../../../constants/constants";
import Seminar from "../Seminar/Seminar";
import Payment from "../Payment/Payment";
import Certificate from "../Certificate/Certificate";



export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isLoading:false,
          isWarningConfirMsg:false,
          isSuccessConfirMsg:false,
          isFailedConfirMsg:false,
          _id:"",
          message: null,
          title:"",
          formFields: {},
          errors: {},
          messageElement: false,
          success: false,
          file:"",
          s3file:"",
          isLoader:false,
          image:"",
          fromDate:new Date(),
          isSameAddress:false
        };
      }

     
      
    render() {
        var AuthDetails=JSON.parse(localStorage.getItem("dmvUser"))
        console.log("YYYYYYYYYYYYYY",this.props.location)
       
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12 padding-30">
                        <div className="row">

                            <div className="col-sm-6">
                                <h4>Welcome {AuthDetails.firstName+" "+AuthDetails.middleName+" "+AuthDetails.lastName}</h4>
                                
                                {
                                    (this.props.location.pathname==="/portal/dashboard/certificate/seminar-1"
                                    || this.props.location.pathname==="/portal/dashboard/certificate/seminar-2"
                                    || this.props.location.pathname==="/portal/dashboard/certificate/seminar-3")?
                                    (
                                    <>
                                        <h6>You have completed the following Seminar.</h6>
                                    </>
                                    ):
                                    (
                                       (this.props.location.pathname==="/portal/dashboard")?
                                       (
                                        <>
                                            <h6>Click on the Seminar you would like to enroll in.</h6>
                                        </>
                                       ):
                                       (
                                           <>
                                           </>
                                       ) 
                                    )
                                }
                            </div>
                            <div className="col-sm-6 text-right certificate-note-position">
                                {
                                    (this.props.location.pathname==="/portal/dashboard/certificate/seminar-1"
                                    || this.props.location.pathname==="/portal/dashboard/certificate/seminar-2"
                                    || this.props.location.pathname==="/portal/dashboard/certificate/seminar-3")?
                                    (
                                    <>
                                        <span className="certificate-note">Click on the green box below to save or print a copy of the certificate</span>
                                        
                                    </>
                                    ):
                                    (
                                       (this.props.location.pathname==="/portal/dashboard")?
                                       (
                                        <>
                                            <span>After you complete a Seminar the button will turn green,</span>
                                            <p>To Enroll in the next seminar,just click on the button.</p>
                                        </>
                                       ):
                                       (
                                           <>
                                           </>
                                       ) 
                                    )
                                }
                                
                            </div>
                            
                        </div>
                        <div  className="row seminar-div justify-content-center align-items-center">
                     
                        <Switch>
                            <Route exact path="/portal/dashboard" component={Seminar} />
                            <Route  path="/portal/dashboard/pay/:id" component={Payment} />
                            <Route  path="/portal/dashboard/certificate/:id" component={Certificate} />
                        </Switch>
                        </div>
                    </div>
                </div>
            </div>
            
        );
    }
}
