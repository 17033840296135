import React, { Component } from "react";
import { Switch, Route} from "react-router-dom";
import Dashboard from '../../../components/mainComponents/Dashboard/Dashboard'
import CourseWrapper from '../../../components/mainComponents/CourseWrapper/CourseWrapper'
import TestWrapper from "../../../components/mainComponents/TestWrapper/TestWrapper";

export default class ContentWrapper extends Component {
    render() {

        return (
            <div className="conainer">
                {/* <Dashboard/> */}
                {/* <CourseWrapper/> */}
                <Switch>
                    <Route  path="/portal/dashboard/" component={Dashboard} />
                    <Route  path="/portal/seminar/:id" component={CourseWrapper} />
                    <Route  path="/portal/test/:id" component={TestWrapper} />
                    <Route  path="/portal/pay/:id" component={TestWrapper} />
                </Switch>
            </div>
        );
    }
}
