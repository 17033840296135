import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { reducers } from "./reducers";
import { createLogger } from "redux-logger";

let initialState = {
  delete:{
    isDeleting:false,
    isSuccess:false,
    msg:"",
  },
  add:{
    isAdding:false,
    isSuccess:false,
    msg:"",
  },
  update:{
    isSuccess:false,
    msg:"",
    isUpdating:false
  },
  signin:{
    isSignIn:false,
    result:JSON.parse(localStorage.getItem('styleUserToken'))?JSON.parse(localStorage.getItem('styleUserToken')):{},
    isSuccess:false,
    msg:"",
  },
  question:{
    isQuestionFetching:false,
    list:[],
    totalCount:0,
    filter: {}
  },
  content:{
    isContentFetching:false,
    list:[],
    totalCount:0,
    filter: {}
  },
  seminar:{
    isSeminarFetching:false,
    list:[],
    totalCount:0,
    filter: {}
  }
};

let logger = createLogger();

export const store = createStore(
  reducers,
  initialState,
  compose(
    applyMiddleware(thunkMiddleware, logger),
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      : f => f
  )
);
