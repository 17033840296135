export const MESSAGE = {
    SUCCESS_TITLE:"SUCCESS",
    FAILED_TITLE:"FAILED",
    SIGNUP:"Sign Up Successfully",
    CHANGE_PASSWORD:"Password Change Successfully",
    PRODUCT_POST:"Product Added Successfully",
    UPDATE_PROFILE:"Profile Updated Successfully",
    ADD_TO_CART:"Add to Cart Successfully",
    CONTACT_US:"Request Send Successfully",
    ORDER_PLACE:"Order Place Successfully",
    PRODUCT_REMOVE:"Product Remove Successfully",
    IMAGE_UPDATE:"Image Updated Successfully",
    CART_LIST_UPDATE:"Product Updated Successfully.",
    OTP_EMAIL:"Otp send to your Email address.",
    OTP_MOBILE_NUMBER:"Otp send to your Mobile Number address.",
    PAYMENT:"Payment Accepted Successfully.."
}