import React, { Component } from 'react'
import { Header, Footer } from '../defaultLayout';
import ContentWrapper  from '../../Layout/defaultLayout/contentWrapper/ContentWrapper'

export default class Portal extends Component {
    render() {
        return (
            <div className="main-container">
                    <div className="container">
                        <div className="mainsection">
                        <header>
                            <Header/>
                        </header>                
                        <main className="">
                            <ContentWrapper />
                        </main>
                        <footer>
                            <Footer />
                        </footer>
                        </div>
                    
                </div>
                
            </div>
        )
    }
}
