import { ACTIONS } from "../../action-constants/Actions"


const SeminarFetching=()=>{
    return {
        type: ACTIONS.SEMINAR_ACTION.GET_SEMINAR.SEMINAR_FETCHING,
        payload:{
            isSeminarFetching:true,
        },
        isSuccess: false,
    }
}
const SeminarFetchingFailed=({ result, msg })=>{
    return {
        type: ACTIONS.SEMINAR_ACTION.GET_SEMINAR.SEMINAR_FETCHING_FAILED,
        payload:{
            isSeminarFetching:false,
            result,
            msg
        },
        isSuccess: false,
    }
}
const SeminarFetched=({ result })=>{
    return {
        type: ACTIONS.SEMINAR_ACTION.GET_SEMINAR.SEMINAR_FETCHED,
        payload:{
            isSeminarFetching:false,
            result:result,
        },
        isSuccess: true,
    }
}
export const Seminar = {
    SeminarFetching,
    SeminarFetchingFailed,
    SeminarFetched
}