import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import {InputBox,LoadingComponent} from '../../commonComponents/index';
import { ChangePasswordApi } from '../../../../store/api-actions/auth/changePasswordApi';
import { connect } from "react-redux";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';




const mapDispatchToProps = dispatch => {
    return {
        changepassword: (reqData) => dispatch(ChangePasswordApi(reqData))
    };
  };
  
const mapStateToProps = state => {
    return {
        
  };
};

class Changepassword extends Component {


    constructor(props){
        super(props);
        this.state= {
            isLoading:false,
            isWarningConfirMsg:false,
            isSuccessConfirMsg:false,
            isFailedConfirMsg:false,
            _id:"",
            message:"",
            title:"",
            status:0,
            mobileNumber:"",
            email:""
        }
    }

   
    handleChangePassword = (values, formikBag)=> {
        this.setState({isLoading:true});
        values.oldPassword = values.currentPassword
        this.props.changepassword(values).then(res => {
            if(res.status){
                this.setState({isSuccessConfirMsg:true,isLoading:false,message:MESSAGE.CHANGE_PASSWORD,title:MESSAGE.SUCCESS_TITLE});
            }else{
                this.setState({isFailedConfirMsg:true,isLoading:false,message:res.message,title:MESSAGE.FAILED_TITLE});
            }
            
        })
    }

    callbackFunction = (childData) => {
        console.log("++))))(((======",childData)
         
        
    }

    callbackFunctionOtp = (childData) => {
        this.setState({status:0,
            mobileNumber:"",
            email:""});
    }

    render() {
            var conm = "";
            var loader = ""
            var obj = {
                title:this.state.title,
                onConfirm:()=>{
                this.setState({isFailedConfirMsg:false,isSuccessConfirMsg:false});
                },
                subtitle:this.state.message
            }

            if(this.state.isFailedConfirMsg){
                conm = swal.failed(obj);
            }
            
            if(this.state.isSuccessConfirMsg){
                conm = swal.success(obj);
            }
            if(this.state.isLoading){
                loader = <LoadingComponent/>
            }
            
        

        return (
            <div className="change-password-area">
                {loader}
                {conm}
                <div className="heading">
                    <h2>Change Password,Email and Mobile Number</h2>
                    <hr/>
                </div>
                <div className="changepassword-inner">
                    <Row>
                        <Col md="12" lg="12">
                        <Formik
                            initialValues={{
                                currentPassword:'',
                                newPassword:'',
                                confirmPassword:''
                            }}
                            onSubmit={this.handleChangePassword}
                            validationSchema={VALIDATORS.CHANGEPASSWORD}
                            render={({ handleSubmit, errors, touched, values, handleChange })=>(
                                <form onSubmit={handleSubmit}>
                                    <InputBox
                                        labelClass="auth-label"
                                        formWrapClass = "form-group auth-form-field"
                                        labelText="Enter Current Password*"
                                        inputClass="form-control"
                                        type="password"
                                        placeholder=""
                                        name = "currentPassword"
                                        handleChange={handleChange}
                                        value={values.currentPassword}
                                        errorClass = "text-danger"
                                        errorText = { touched.currentPassword && errors.currentPassword }
                                    />
                                    <InputBox
                                        labelClass="auth-label"
                                        formWrapClass = "form-group auth-form-field"
                                        labelText="Enter New Password*"
                                        inputClass="form-control"
                                        type="password"
                                        placeholder=""
                                        name = "newPassword"
                                        handleChange={handleChange}
                                        value={values.newPassword}
                                        errorClass = "text-danger"
                                        errorText = { touched.newPassword && errors.newPassword }
                                    />
                                    <InputBox
                                        labelClass="auth-label"
                                        formWrapClass = "form-group auth-form-field"
                                        labelText="Re-Enter New Password*"
                                        inputClass="form-control"
                                        type="password"
                                        placeholder=""
                                        name = "confirmPassword"
                                        handleChange={handleChange}
                                        value={values.confirmPassword}
                                        errorClass = "text-danger"
                                        errorText = { touched.confirmPassword && errors.confirmPassword }
                                    />  
                                    <div className="actions-toolbar">
                                        <div className="primary">
                                            <button type="submit" className="btn btn-dark submit">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )}
                            />
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col className="col-md-6" ms="6">
                         
                       
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-6" ms="6">
                         
                        
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Changepassword  = connect(mapStateToProps, mapDispatchToProps)(Changepassword);
