import { COMMON_FUNCTION } from "./commonFunction";
import { Container, Row, Col } from "react-bootstrap";
import { END_POINT } from "./apiEndPoints";

import img1 from '../assets/images/3.jpg'
import img3 from '../assets/images/3.jpg'
import img4 from '../assets/images/4.jpg'
import img5 from '../assets/images/5.jpg'
import img6 from '../assets/images/6.png'
import img7 from '../assets/images/7.jpg'
import img8 from '../assets/images/8.png'
import img9 from '../assets/images/9.gif'
import img11 from '../assets/images/11.jpg'
import img12 from '../assets/images/12.jpg'
import img14 from '../assets/images/14.jpg'
import img16 from '../assets/images/16.png'
import img17 from '../assets/images/17.png'
import img18 from '../assets/images/18.jpg'
import img19 from '../assets/images/19.png'
import img20 from '../assets/images/20.png'
import img21 from '../assets/images/21.jpg'
import img22 from '../assets/images/22.jpg'
import img23 from '../assets/images/23.jpg'
import img24 from '../assets/images/24.jpg'
import img27 from '../assets/images/27.png'
import img28 from '../assets/images/28.png'
import img29 from '../assets/images/29.png'
import img30 from '../assets/images/30.jpg'
import img31 from '../assets/images/31.png'
import img32 from '../assets/images/32.png'
import img38 from '../assets/images/38.tif'
import img39 from '../assets/images/39.png'
import img40 from '../assets/images/40.gif'
import img41 from '../assets/images/41.jpg'
import img42 from '../assets/images/42.png'
import img43 from '../assets/images/43.png'
import img44 from '../assets/images/44.jpg'
import img46 from '../assets/images/46.png'
import img47 from '../assets/images/47.png'
import img48 from '../assets/images/48.png'
import img49 from '../assets/images/49.png'
import img50 from '../assets/images/50.png'
import img51 from '../assets/images/51.jpg'
import img52 from '../assets/images/52.png'
import img52jpg from '../assets/images/52'


export const CONSTANTS = {
  // MAINDIV:{
  //   "position": "absolute",
  //   // "bottom": "24%",
  //   // "left": "0",
  //   // "font-size": "31px",
  //   "color": "rgb(87, 87, 82)",
  //   "font-weight": "500",
  //   "right": "0",
  //   // "width": "100%"
  // },
  // NAMEDIV:{
  //   // "font-style": "italic",
  //   //  "text-align":"center",
  //   //  "margin-bottom": "20px"
  // },
  // SEMINARDIV:{
  //  "text-align": "center",
  //   "margin-bottom": "91px",
  //   "font-size": "18px",
  //   "margin-left": "56px"
  // },
  // CONTENTDIV:{
  //   "text-align": "center",
  //   "width": "744px",
  //   "margin":"0 auto"
  // },
  // PARA:{
  //   "font-size": "15px"
  // },
  // PARA2:{
  //   "font-size": "15px",
  //   "margin-bottom":"2px"
  // },
  // schoolname:{
  //   // "font-size": "14px",
  //   "margin-top": "20px",
  //   // "margin-bottom": "43px",
  // },
  // InstructorLicense:{
  //   "margin-top": "27px",
  //   // "margin-left": "23%"
  // },
  // Newclass:{
  //  "text-align": "end",
  //   "margin-right": "65px",

  // },
  // signature:{
  //   "font-size": "20px",
  //    "text-align":"center",
  //   "margin-top": "39px"
  // },
  MAINDIV: {
    "position": "absolute",
    "bottom": "28%",
    "left": "0",
    "font-size": "31px",
    "color": "rgb(87, 87, 82)",
    "font-weight": "500",
    "right": "0",
    "width": "100%"
  },
  NAMEDIV: {
    "font-style": "italic",
    "text-align": "center"
  },
  CONTENTDIV: {
    "text-align": "center",
    "width": "744px",
    "margin": "0 auto"
  },
  PARA: {
    "font-size": "15px"
  },
  PARA2: {
    "font-size": "15px",
    "margin-bottom": "2px"
  },
  schoolname: {
    "font-size": "14px",
    "text-align": "center",
    "margin-top": "20px"
  },
  signature: {
    "font-size": "20px",
    "text-align": "center",
    "margin-top": "39px"
  },
  GENDER: [
    { key: '0', value: '0', text: 'Male' },
    { key: '1', value: '1', text: 'Female' },
    { key: '2', value: '2', text: 'Other' }
  ],
  STATE: [
    { text: "Alabama", value: "AL", key: 0 },
    { text: "Alaska", value: "AK", key: 1 },
    { text: "American Samoa", value: "AS", key: 2 },
    { text: "Arizona", value: "AZ", key: 3 },
    { text: "Arkansas", value: "AR", key: 4 },
    { text: "California", value: "CA", key: 5 },
    { text: "Colorado", value: "CO", key: 6 },
    { text: "Connecticut", value: "CT", key: 7 },
    { text: "Delaware", value: "DE", key: 8 },
    { text: "District Of Columbia", value: "DC", key: 9 },
    { text: "Federated States Of Micronesia", value: "FM", key: 10 },
    { text: "Florida", value: "FL", key: 11 },
    { text: "Georgia", value: "GA", key: 12 },
    { text: "Guam", value: "GU", key: 13 },
    { text: "Hawaii", value: "HI", key: 14 },
    { text: "Idaho", value: "ID", key: 15 },
    { text: "Illinois", value: "IL", key: 16 },
    { text: "Indiana", value: "IN", key: 17 },
    { text: "Iowa", value: "IA", key: 18 },
    { text: "Kansas", value: "KS", key: 19 },
    { text: "Kentucky", value: "KY", key: 20 },
    { text: "Louisiana", value: "LA", key: 21 },
    { text: "Maine", value: "ME", key: 22 },
    { text: "Marshall Islands", value: "MH", key: 23 },
    { text: "Maryland", value: "MD", key: 24 },
    { text: "Massachusetts", value: "MA", key: 25 },
    { text: "Michigan", value: "MI", key: 26 },
    { text: "Minnesota", value: "MN", key: 27 },
    { text: "Mississippi", value: "MS", key: 28 },
    { text: "Missouri", value: "MO", key: 29 },
    { text: "Montana", value: "MT", key: 30 },
    { text: "Nebraska", value: "NE", key: 31 },
    { text: "Nevada", value: "NV", key: 32 },
    { text: "New Hampshire", value: "NH", key: 33 },
    { text: "New Jersey", value: "NJ", key: 34 },
    { text: "New Mexico", value: "NM", key: 35 },
    { text: "New York", value: "NY", key: 36 },
    { text: "North Carolina", value: "NC", key: 37 },
    { text: "North Dakota", value: "ND", key: 38 },
    { text: "Northern Mariana Islands", value: "MP", key: 39 },
    { text: "Ohio", value: "OH", key: 40 },
    { text: "Oklahoma", value: "OK", key: 41 },
    { text: "Oregon", value: "OR", key: 42 },
    { text: "Palau", value: "PW", key: 43 },
    { text: "Pennsylvania", value: "PA", key: 44 },
    { text: "Puerto Rico", value: "PR", key: 45 },
    { text: "Rhode Island", value: "RI", key: 46 },
    { text: "South Carolina", value: "SC", key: 47 },
    { text: "South Dakota", value: "SD", key: 48 },
    { text: "Tennessee", value: "TN", key: 49 },
    { text: "Texas", value: "TX", key: 50 },
    { text: "Utah", value: "UT", key: 51 },
    { text: "Vermont", value: "VT", key: 52 },
    { text: "Virgin Islands", value: "VI", key: 53 },
    { text: "Virginia", value: "VA", key: 54 },
    { text: "Washington", value: "WA", key: 55 },
    { text: "West Virginia", value: "WV", key: 56 },
    { text: "Wisconsin", value: "WI", key: 57 },
    { text: "Wyoming", value: "WY", key: 58 }
  ],
  YEAR: COMMON_FUNCTION.YEARS(),
  MONTH: COMMON_FUNCTION.MONTH(),
  AuthDetails: JSON.parse(localStorage.getItem("dmvUser")),
  SEMINAR: {
    "seminar-1": [
      {
        index: "Introduction",
        text: "<div> \
          <h2>Introduction</h2>\
          <hr/> \
          <p> \
          It is important to be mentally focused, prepared, and know the law, in order to be a safe driver. \
          </p> \
          <p> \
          Course 1 will examine the various factors that contribute to creating a good driver including having the proper frame of mind that one must have before getting behind the wheel, as well as knowing the rules of the road. \
          </p> \
        </div>"
      },
      {
        index: "Attitude",
        text: "<div> \
          <h2>Attitude</h2> \
          <hr/> \
          <div> \
            <p> \
            As part of being a safe and responsible driver, not only do you need to know and follow the rules of the road, you need to use common sense, and be thoughtful and courteous to other drivers, pedestrians, and bicyclists.\
            </p>\
            <p>\
            This means that you share the road responsibly, give the right of way, and do not take actions that can cause risk or harm for others.        \
            </p>\
            <p> \
              There are positive steps that responsible drivers can take to help insure their own driving safety and pleasure, as well as that of their passengers, and others sharing the road and highways.    \
            </p>\
          </div>\
          <div class='row'>\
            <div class='col-md-12'><h4>Tips For Being A Thoughtful And Courteous Driver</h4></div>\
            <div class='col-md-9'>\
            <ul>\
              <li>Always allow a safe distance between your vehicle and the vehicle in front of you, so you can react to a traffic situation quickly and allow other drivers to maneuver around your car safely when needed.</li>\
              <li>Always drive at a safe speed based on the speed limit, flow of traffic, weather, road surface, and traffic situation.</li>\
              <li>When entering a freeway or changing lanes, if you can, do not make other drivers brake in order to allow you into their space.</li>\
              <li>Research shows that sticking to the middle lane is probably the quickest and safest way to get to your destination.  Therefore, drive in the right or middle lane and pass on the left.</li>\
              <li>Always signal in advance before turning or changing lanes.</li>\
              <li>Fully stop at a stop sign and never run a red light.</li>\
              <li>Always give the right of way to pedestrians..</li>\
              <li>Do not block intersections.</li>\
              <li>Never tailgate or drive recklessly.</li>\
              <li>Never succumb to road rage.</li>\
              <li>Never drive too slowly in the fast lane of the highway (left lanes are typically used for passing or faster speed; maintain a safe speed with the flow of traffic).</li>\
            </ul>\
            </div>\
            <div class='col-md-3'><img class='width-100' src='"+ END_POINT.IMG_URL + "1.jpg'/></div>\
          </div>\
          <div>\
            <p>\
            Having a positive attitude and being courteous will not only make your traveling time safer and more enjoyable; it helps makes the road a little safer for everyone else. \
            </p>\
            <iframe width='560' height='315' src='https://www.youtube.com/embed/ZPlxaSukv2M' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>\
          </div>\
        </div>"
      },
      {
        index: "Stress, Anger, Emotions, and Fatigue",
        text: '<div class="container">\
          <h2>Stress, Anger, Emotions, and Fatigue</h2>\
          <hr/>\
          <div class="stress">\
            <h4>Stress:</h4>\
            <p>\
            Stress is a problem that is becoming more prevalent. It can cause fatigue, emotional problems, and even illness. When coupled with driving, it can cause angry encounters, aggressive driving, road rage, and unfortunately it can lead to injuries and accidents.\
            </p>\
            <ul>\
              <li>Many factors influence one’s stress levels. Driving at peak times and in congested areas can cause the most stress.</li>\
              <li>The feeling of being stuck in traffic and the inability to do anything, even after the traffic light changes to green – can elevate your stress levels.</li>\
              <li>The stress of everyday life, like work or personal problems often contribute to feeling tense on the road too. </li>\
              <li>The effects of stress on people range from making you physically unwell and affecting your emotions, to not being able to concentrate, or making a person snap and rage at others.</li>\
            </ul>\
            <div class="text-center">\
              <img class="pull-center" src='+ img1 + '/>\
            </div>\
          </div>\
          <div class="clearfix">&nbsp;</div>\
          <div class="content">  \
            <h4>How to Recognize Escalating Emotions:</h4>\
            <p>\
            Heightened emotions such as stress or anger are forms of cognitive distraction that can significantly impede a drivers’ ability to make wise choices while driving.  Stress can affect you both emotionally and physically, and it can affect the way you behave.  Some of the most common signs of stress include being unable to concentrate, being irritable or restless, and not being able to sleep.  Lack of sleep causes fatigue and an inability to make decisions. Stress can also cause memory problems, an inability to focus, constant worrying, and anxiousness. \
            </p>\
            <p> \
            Anger can carry over from events that happen before a person starts driving.  A driver may find it difficult to focus attention to driving, may become frustrated, and then become angry. This impairs driving, and can create hazards for other drivers.\
            </p>\
            <p> \
            Stress differs from anger because drivers can often recognize stress and try to avoid it. Angry drivers have more difficulty calming themselves. Drivers suffering from stress may more easily realize when they are in trouble and try their best to successfully complete their trip. If unrecognized though, stress can lead to anger towards others on the road. Drivers who are upset may make panicked decisions because they have difficulty identifying potential hazards and predicting the actions of other drivers.\
            </p>\
            <div class="text-center">\
              <img src='+ img3 + ' />\
            </div>\
          </div>\
          <div class="content">\
            <h4>How Does Stress Affect Driving?</h4>\
            <p>\
            Angry, stressed, fatigued, and emotionally distressed drivers affect the other drivers on the road. They will usually speed, cut in and out of traffic, and do a lot of yelling.  Anger and stress on the road causes chaos.\
            </p>\
            <div class="text-center">\
              <img src='+ img4 + ' />\
            </div>\
            <div>\
              <h4>Risks of Stress and Anger When Driving:</h4>\
              <h6>Stress and anger greatly increase the danger of accidents.  Drivers often:</h6>\
              <ul>\
                <li>Get upset over insignificant incidences, can’t concentrate, or become nervous in unusual situations.</li>\
                <li>Lose their tempers and have lack of good judgment.</li>\
                <li>Express anger by driving recklessly. </li>\
                <li>Show impatience in traffic jams by useless horn blowing.</li>\
                <li>Flash their high-beam lights in the eyes of approaching drivers.</li>\
                <li>Talk loudly or use profanity.</li>\
                <li>Resort to crowding and forcing others out of traffic lanes..</li>\
              </ul>\
            </div>\
          </div>\
          <div class="content">\
            <h4>Mental and Physical:</h4>\
            <p>\
              Any driver with signs of mental or emotional disturbances may be unable to cope with a difficult situation, and this makes them an unsafe driver. Such disturbances may cause the driver serious anxiety, tension, aggression, or anger, especially in their interaction with other drivers. To help avoid being an accident:\
            </p>\
            <ul>\
              <li>Have regular medical checkups, and maintain health.</li>\
              <li>Avoid driving in stressful traffic situations.</li>\
              <li>Drive in familiar areas.</li>\
              <li>Keep farther distances from the cars ahead.</li>\
              <li>Concentrate on the whole traffic scene.</li>\
              <li>Don"\'"t drive when emotionally upset or angry, worried, or daydreaming.</li>\
              <li>Avoid prolonged hours of driving.</li>\
              <li>Do not drive if you are tired or sleepy.</li>\
            </ul>\
          </div>\
          <div class="content">\
            <h4>Avoiding Fatigue:</h4>\
            <p>\
            Use techniques for avoiding drowsiness for a short time like stopping often, getting fresh air,  or singing. If you find you have heavy eyes, are yawning, and daydreaming while driving, these are warning signs of fatigue, and it is best to is to pull off the road and get some rest.\
            </p>\
            <div class="text-center">\
              <img src='+ img5 + ' />\
            </div>\
          </div>\
          <div class="content">\
            <h4>When You Should Ask for Help:</h4>\
            <div class="row">\
              <div class="col-md-2">\
                <img class="width-100" src='+ img6 + ' />\
              </div>\
              <div class="col-md-8">\
              <p>\
                Drinking while driving is another physical enemy on the road. Set up a designated driver if you are going to be drinking alcohol; don’t ever take a chance with lives. \
              </p>\
              <p>\
              If You Drink – Don’t Drive!\
              </p>\
              </div>\
              <div class="col-md-2">\
                <img class="width-100" src='+ img7 + ' />\
              </div>\
            </div>\
          </div>\
          <div class="content">\
            <p>\
            If you have a broken limb, or other ailment that can keep you from making quick movements and judgments while on the road, or if you have a physical problem or medical condition that impairs your ability to safely operate a vehicle, get a friend or relative to drive you. \
            </p>\
          </div>\
        </div>'
      },
      {
        index: "Anger",
        text: '<div class="container">\
          <h2>Stress, Anger, Emotions, and Fatigue</h2>\
          <hr/>\
          <div class="stress">\
            <h4>Stress:</h4>\
            <p>\
            Stress is a problem that is becoming more prevalent. It can cause fatigue, emotional problems, and even illness. When coupled with driving, it can cause angry encounters, aggressive driving, road rage, and unfortunately it can lead to injuries and accidents.\
            </p>\
            <ul>\
              <li>Many factors influence one’s stress levels. Driving at peak times and in congested areas can cause the most stress.</li>\
              <li>The feeling of being stuck in traffic and the inability to do anything, even after the traffic light changes to green – can elevate your stress levels.</li>\
              <li>The stress of everyday life, like work or personal problems often contribute to feeling tense on the road too. </li>\
              <li>The effects of stress on people range from making you physically unwell and affecting your emotions, to not being able to concentrate, or making a person snap and rage at others.</li>\
            </ul>\
            <div class="text-center">\
              <img class="pull-center" src='+ img1 + '/>\
            </div>\
          </div>\
          <div class="clearfix">&nbsp;</div>\
          <div class="content">  \
            <h4>How to Recognize Escalating Emotions:</h4>\
            <p>\
            Heightened emotions such as stress or anger are forms of cognitive distraction that can significantly impede a drivers’ ability to make wise choices while driving.  Stress can affect you both emotionally and physically, and it can affect the way you behave.  Some of the most common signs of stress include being unable to concentrate, being irritable or restless, and not being able to sleep.  Lack of sleep causes fatigue and an inability to make decisions. Stress can also cause memory problems, an inability to focus, constant worrying, and anxiousness. \
            </p>\
            <p> \
            Anger can carry over from events that happen before a person starts driving.  A driver may find it difficult to focus attention to driving, may become frustrated, and then become angry. This impairs driving, and can create hazards for other drivers.\
            </p>\
            <p> \
            Stress differs from anger because drivers can often recognize stress and try to avoid it. Angry drivers have more difficulty calming themselves. Drivers suffering from stress may more easily realize when they are in trouble and try their best to successfully complete their trip. If unrecognized though, stress can lead to anger towards others on the road. Drivers who are upset may make panicked decisions because they have difficulty identifying potential hazards and predicting the actions of other drivers.\
            </p>\
            <div class="text-center">\
              <img src='+ img3 + ' />\
            </div>\
          </div>\
          <div class="content">\
            <h4>How Does Stress Affect Driving?</h4>\
            <p>\
            Angry, stressed, fatigued, and emotionally distressed drivers affect the other drivers on the road. They will usually speed, cut in and out of traffic, and do a lot of yelling.  Anger and stress on the road causes chaos.\
            </p>\
            <div class="text-center">\
              <img src='+ img4 + ' />\
            </div>\
            <div>\
              <h4>Risks of Stress and Anger When Driving:</h4>\
              <h6>Stress and anger greatly increase the danger of accidents.  Drivers often:</h6>\
              <ul>\
                <li>Get upset over insignificant incidences, can’t concentrate, or become nervous in unusual situations.</li>\
                <li>Lose their tempers and have lack of good judgment.</li>\
                <li>Express anger by driving recklessly. </li>\
                <li>Show impatience in traffic jams by useless horn blowing.</li>\
                <li>Flash their high-beam lights in the eyes of approaching drivers.</li>\
                <li>Talk loudly or use profanity.</li>\
                <li>Resort to crowding and forcing others out of traffic lanes..</li>\
              </ul>\
            </div>\
          </div>\
          <div class="content">\
            <h4>Mental and Physical:</h4>\
            <p>\
              Any driver with signs of mental or emotional disturbances may be unable to cope with a difficult situation, and this makes them an unsafe driver. Such disturbances may cause the driver serious anxiety, tension, aggression, or anger, especially in their interaction with other drivers. To help avoid being an accident:\
            </p>\
            <ul>\
              <li>Have regular medical checkups, and maintain health.</li>\
              <li>Avoid driving in stressful traffic situations.</li>\
              <li>Drive in familiar areas.</li>\
              <li>Keep farther distances from the cars ahead.</li>\
              <li>Concentrate on the whole traffic scene.</li>\
              <li>Don"\'"t drive when emotionally upset or angry, worried, or daydreaming.</li>\
              <li>Avoid prolonged hours of driving.</li>\
              <li>Do not drive if you are tired or sleepy.</li>\
            </ul>\
          </div>\
          <div class="content">\
            <h4>Avoiding Fatigue:</h4>\
            <p>\
            Use techniques for avoiding drowsiness for a short time like stopping often, getting fresh air,  or singing. If you find you have heavy eyes, are yawning, and daydreaming while driving, these are warning signs of fatigue, and it is best to is to pull off the road and get some rest.\
            </p>\
            <div class="text-center">\
              <img src='+ img5 + ' />\
            </div>\
          </div>\
          <div class="content">\
            <h4>When You Should Ask for Help:</h4>\
            <div class="row">\
              <div class="col-md-2">\
                <img class="width-100" src='+ img6 + ' />\
              </div>\
              <div class="col-md-8">\
              <p>\
                Drinking while driving is another physical enemy on the road. Set up a designated driver if you are going to be drinking alcohol; don’t ever take a chance with lives. \
              </p>\
              <p>\
              If You Drink – Don’t Drive!\
              </p>\
              </div>\
              <div class="col-md-2">\
                <img class="width-100" src='+ img7 + ' />\
              </div>\
            </div>\
          </div>\
          <div class="content">\
            <p>\
            If you have a broken limb, or other ailment that can keep you from making quick movements and judgments while on the road, or if you have a physical problem or medical condition that impairs your ability to safely operate a vehicle, get a friend or relative to drive you. \
            </p>\
          </div>\
        </div>'
      },
      {
        index: "Emotions",
        text: '<div class="container">\
          <h2>Stress, Anger, Emotions, and Fatigue</h2>\
          <hr/>\
          <div class="stress">\
            <h4>Stress:</h4>\
            <p>\
            Stress is a problem that is becoming more prevalent. It can cause fatigue, emotional problems, and even illness. When coupled with driving, it can cause angry encounters, aggressive driving, road rage, and unfortunately it can lead to injuries and accidents.\
            </p>\
            <ul>\
              <li>Many factors influence one’s stress levels. Driving at peak times and in congested areas can cause the most stress.</li>\
              <li>The feeling of being stuck in traffic and the inability to do anything, even after the traffic light changes to green – can elevate your stress levels.</li>\
              <li>The stress of everyday life, like work or personal problems often contribute to feeling tense on the road too. </li>\
              <li>The effects of stress on people range from making you physically unwell and affecting your emotions, to not being able to concentrate, or making a person snap and rage at others.</li>\
            </ul>\
            <div class="text-center">\
              <img class="pull-center" src='+ img1 + '/>\
            </div>\
          </div>\
          <div class="clearfix">&nbsp;</div>\
          <div class="content">  \
            <h4>How to Recognize Escalating Emotions:</h4>\
            <p>\
            Heightened emotions such as stress or anger are forms of cognitive distraction that can significantly impede a drivers’ ability to make wise choices while driving.  Stress can affect you both emotionally and physically, and it can affect the way you behave.  Some of the most common signs of stress include being unable to concentrate, being irritable or restless, and not being able to sleep.  Lack of sleep causes fatigue and an inability to make decisions. Stress can also cause memory problems, an inability to focus, constant worrying, and anxiousness. \
            </p>\
            <p> \
            Anger can carry over from events that happen before a person starts driving.  A driver may find it difficult to focus attention to driving, may become frustrated, and then become angry. This impairs driving, and can create hazards for other drivers.\
            </p>\
            <p> \
            Stress differs from anger because drivers can often recognize stress and try to avoid it. Angry drivers have more difficulty calming themselves. Drivers suffering from stress may more easily realize when they are in trouble and try their best to successfully complete their trip. If unrecognized though, stress can lead to anger towards others on the road. Drivers who are upset may make panicked decisions because they have difficulty identifying potential hazards and predicting the actions of other drivers.\
            </p>\
            <div class="text-center">\
              <img src='+ img3 + ' />\
            </div>\
          </div>\
          <div class="content">\
            <h4>How Does Stress Affect Driving?</h4>\
            <p>\
            Angry, stressed, fatigued, and emotionally distressed drivers affect the other drivers on the road. They will usually speed, cut in and out of traffic, and do a lot of yelling.  Anger and stress on the road causes chaos.\
            </p>\
            <div class="text-center">\
              <img src='+ img4 + ' />\
            </div>\
            <div>\
              <h4>Risks of Stress and Anger When Driving:</h4>\
              <h6>Stress and anger greatly increase the danger of accidents.  Drivers often:</h6>\
              <ul>\
                <li>Get upset over insignificant incidences, can’t concentrate, or become nervous in unusual situations.</li>\
                <li>Lose their tempers and have lack of good judgment.</li>\
                <li>Express anger by driving recklessly. </li>\
                <li>Show impatience in traffic jams by useless horn blowing.</li>\
                <li>Flash their high-beam lights in the eyes of approaching drivers.</li>\
                <li>Talk loudly or use profanity.</li>\
                <li>Resort to crowding and forcing others out of traffic lanes..</li>\
              </ul>\
            </div>\
          </div>\
          <div class="content">\
            <h4>Mental and Physical:</h4>\
            <p>\
              Any driver with signs of mental or emotional disturbances may be unable to cope with a difficult situation, and this makes them an unsafe driver. Such disturbances may cause the driver serious anxiety, tension, aggression, or anger, especially in their interaction with other drivers. To help avoid being an accident:\
            </p>\
            <ul>\
              <li>Have regular medical checkups, and maintain health.</li>\
              <li>Avoid driving in stressful traffic situations.</li>\
              <li>Drive in familiar areas.</li>\
              <li>Keep farther distances from the cars ahead.</li>\
              <li>Concentrate on the whole traffic scene.</li>\
              <li>Don"\'"t drive when emotionally upset or angry, worried, or daydreaming.</li>\
              <li>Avoid prolonged hours of driving.</li>\
              <li>Do not drive if you are tired or sleepy.</li>\
            </ul>\
          </div>\
          <div class="content">\
            <h4>Avoiding Fatigue:</h4>\
            <p>\
            Use techniques for avoiding drowsiness for a short time like stopping often, getting fresh air,  or singing. If you find you have heavy eyes, are yawning, and daydreaming while driving, these are warning signs of fatigue, and it is best to is to pull off the road and get some rest.\
            </p>\
            <div class="text-center">\
              <img src='+ img5 + ' />\
            </div>\
          </div>\
          <div class="content">\
            <h4>When You Should Ask for Help:</h4>\
            <div class="row">\
              <div class="col-md-2">\
                <img class="width-100" src='+ img6 + ' />\
              </div>\
              <div class="col-md-8">\
              <p>\
                Drinking while driving is another physical enemy on the road. Set up a designated driver if you are going to be drinking alcohol; don’t ever take a chance with lives. \
              </p>\
              <p>\
              If You Drink – Don’t Drive!\
              </p>\
              </div>\
              <div class="col-md-2">\
                <img class="width-100" src='+ img7 + ' />\
              </div>\
            </div>\
          </div>\
          <div class="content">\
            <p>\
            If you have a broken limb, or other ailment that can keep you from making quick movements and judgments while on the road, or if you have a physical problem or medical condition that impairs your ability to safely operate a vehicle, get a friend or relative to drive you. \
            </p>\
          </div>\
        </div>'
      },
      {
        index: "Fatigue",
        text: '<div class="container">\
          <h2>Stress, Anger, Emotions, and Fatigue</h2>\
          <hr/>\
          <div class="stress">\
            <h4>Stress:</h4>\
            <p>\
            Stress is a problem that is becoming more prevalent. It can cause fatigue, emotional problems, and even illness. When coupled with driving, it can cause angry encounters, aggressive driving, road rage, and unfortunately it can lead to injuries and accidents.\
            </p>\
            <ul>\
              <li>Many factors influence one’s stress levels. Driving at peak times and in congested areas can cause the most stress.</li>\
              <li>The feeling of being stuck in traffic and the inability to do anything, even after the traffic light changes to green – can elevate your stress levels.</li>\
              <li>The stress of everyday life, like work or personal problems often contribute to feeling tense on the road too. </li>\
              <li>The effects of stress on people range from making you physically unwell and affecting your emotions, to not being able to concentrate, or making a person snap and rage at others.</li>\
            </ul>\
            <div class="text-center">\
              <img class="pull-center" src='+ img1 + '/>\
            </div>\
          </div>\
          <div class="clearfix">&nbsp;</div>\
          <div class="content">  \
            <h4>How to Recognize Escalating Emotions:</h4>\
            <p>\
            Heightened emotions such as stress or anger are forms of cognitive distraction that can significantly impede a drivers’ ability to make wise choices while driving.  Stress can affect you both emotionally and physically, and it can affect the way you behave.  Some of the most common signs of stress include being unable to concentrate, being irritable or restless, and not being able to sleep.  Lack of sleep causes fatigue and an inability to make decisions. Stress can also cause memory problems, an inability to focus, constant worrying, and anxiousness. \
            </p>\
            <p> \
            Anger can carry over from events that happen before a person starts driving.  A driver may find it difficult to focus attention to driving, may become frustrated, and then become angry. This impairs driving, and can create hazards for other drivers.\
            </p>\
            <p> \
            Stress differs from anger because drivers can often recognize stress and try to avoid it. Angry drivers have more difficulty calming themselves. Drivers suffering from stress may more easily realize when they are in trouble and try their best to successfully complete their trip. If unrecognized though, stress can lead to anger towards others on the road. Drivers who are upset may make panicked decisions because they have difficulty identifying potential hazards and predicting the actions of other drivers.\
            </p>\
            <div class="text-center">\
              <img src='+ img3 + ' />\
            </div>\
          </div>\
          <div class="content">\
            <h4>How Does Stress Affect Driving?</h4>\
            <p>\
            Angry, stressed, fatigued, and emotionally distressed drivers affect the other drivers on the road. They will usually speed, cut in and out of traffic, and do a lot of yelling.  Anger and stress on the road causes chaos.\
            </p>\
            <div class="text-center">\
              <img src='+ img4 + ' />\
            </div>\
            <div>\
              <h4>Risks of Stress and Anger When Driving:</h4>\
              <h6>Stress and anger greatly increase the danger of accidents.  Drivers often:</h6>\
              <ul>\
                <li>Get upset over insignificant incidences, can’t concentrate, or become nervous in unusual situations.</li>\
                <li>Lose their tempers and have lack of good judgment.</li>\
                <li>Express anger by driving recklessly. </li>\
                <li>Show impatience in traffic jams by useless horn blowing.</li>\
                <li>Flash their high-beam lights in the eyes of approaching drivers.</li>\
                <li>Talk loudly or use profanity.</li>\
                <li>Resort to crowding and forcing others out of traffic lanes..</li>\
              </ul>\
            </div>\
          </div>\
          <div class="content">\
            <h4>Mental and Physical:</h4>\
            <p>\
              Any driver with signs of mental or emotional disturbances may be unable to cope with a difficult situation, and this makes them an unsafe driver. Such disturbances may cause the driver serious anxiety, tension, aggression, or anger, especially in their interaction with other drivers. To help avoid being an accident:\
            </p>\
            <ul>\
              <li>Have regular medical checkups, and maintain health.</li>\
              <li>Avoid driving in stressful traffic situations.</li>\
              <li>Drive in familiar areas.</li>\
              <li>Keep farther distances from the cars ahead.</li>\
              <li>Concentrate on the whole traffic scene.</li>\
              <li>Don"\'"t drive when emotionally upset or angry, worried, or daydreaming.</li>\
              <li>Avoid prolonged hours of driving.</li>\
              <li>Do not drive if you are tired or sleepy.</li>\
            </ul>\
          </div>\
          <div class="content">\
            <h4>Avoiding Fatigue:</h4>\
            <p>\
            Use techniques for avoiding drowsiness for a short time like stopping often, getting fresh air,  or singing. If you find you have heavy eyes, are yawning, and daydreaming while driving, these are warning signs of fatigue, and it is best to is to pull off the road and get some rest.\
            </p>\
            <div class="text-center">\
              <img src='+ img5 + ' />\
            </div>\
          </div>\
          <div class="content">\
            <h4>When You Should Ask for Help:</h4>\
            <div class="row">\
              <div class="col-md-2">\
                <img class="width-100" src='+ img6 + ' />\
              </div>\
              <div class="col-md-8">\
              <p>\
                Drinking while driving is another physical enemy on the road. Set up a designated driver if you are going to be drinking alcohol; don’t ever take a chance with lives. \
              </p>\
              <p>\
              If You Drink – Don’t Drive!\
              </p>\
              </div>\
              <div class="col-md-2">\
                <img class="width-100" src='+ img7 + ' />\
              </div>\
            </div>\
          </div>\
          <div class="content">\
            <p>\
            If you have a broken limb, or other ailment that can keep you from making quick movements and judgments while on the road, or if you have a physical problem or medical condition that impairs your ability to safely operate a vehicle, get a friend or relative to drive you. \
            </p>\
          </div>\
        </div>'
      },
      {
        index: "Distracted Driving",
        text: '<div class="container">\
          <h2>Stress, Anger, Emotions, and Fatigue</h2>\
          <hr/>\
          <div class="stress">\
            <h4>Stress:</h4>\
            <p>\
            Stress is a problem that is becoming more prevalent. It can cause fatigue, emotional problems, and even illness. When coupled with driving, it can cause angry encounters, aggressive driving, road rage, and unfortunately it can lead to injuries and accidents.\
            </p>\
            <ul>\
              <li>Many factors influence one’s stress levels. Driving at peak times and in congested areas can cause the most stress.</li>\
              <li>The feeling of being stuck in traffic and the inability to do anything, even after the traffic light changes to green – can elevate your stress levels.</li>\
              <li>The stress of everyday life, like work or personal problems often contribute to feeling tense on the road too. </li>\
              <li>The effects of stress on people range from making you physically unwell and affecting your emotions, to not being able to concentrate, or making a person snap and rage at others.</li>\
            </ul>\
            <div class="text-center">\
              <img class="pull-center" src='+ img1 + ' />\
            </div>\
          </div>\
          <div class="clearfix">&nbsp;</div>\
          <div class="content">  \
            <h4>How to Recognize Escalating Emotions:</h4>\
            <p>\
            Heightened emotions such as stress or anger are forms of cognitive distraction that can significantly impede a drivers’ ability to make wise choices while driving.  Stress can affect you both emotionally and physically, and it can affect the way you behave.  Some of the most common signs of stress include being unable to concentrate, being irritable or restless, and not being able to sleep.  Lack of sleep causes fatigue and an inability to make decisions. Stress can also cause memory problems, an inability to focus, constant worrying, and anxiousness. \
            </p>\
            <p> \
            Anger can carry over from events that happen before a person starts driving.  A driver may find it difficult to focus attention to driving, may become frustrated, and then become angry. This impairs driving, and can create hazards for other drivers.\
            </p>\
            <p> \
            Stress differs from anger because drivers can often recognize stress and try to avoid it. Angry drivers have more difficulty calming themselves. Drivers suffering from stress may more easily realize when they are in trouble and try their best to successfully complete their trip. If unrecognized though, stress can lead to anger towards others on the road. Drivers who are upset may make panicked decisions because they have difficulty identifying potential hazards and predicting the actions of other drivers.\
            </p>\
            <div class="text-center">\
              <img src='+ img3 + ' />\
            </div>\
          </div>\
          <div class="content">\
            <h4>How Does Stress Affect Driving?</h4>\
            <p>\
            Angry, stressed, fatigued, and emotionally distressed drivers affect the other drivers on the road. They will usually speed, cut in and out of traffic, and do a lot of yelling.  Anger and stress on the road causes chaos.\
            </p>\
            <div class="text-center">\
              <img src='+ img4 + ' />\
            </div>\
            <div>\
              <h4>Risks of Stress and Anger When Driving:</h4>\
              <h6>Stress and anger greatly increase the danger of accidents.  Drivers often:</h6>\
              <ul>\
                <li>Get upset over insignificant incidences, can’t concentrate, or become nervous in unusual situations.</li>\
                <li>Lose their tempers and have lack of good judgment.</li>\
                <li>Express anger by driving recklessly. </li>\
                <li>Show impatience in traffic jams by useless horn blowing.</li>\
                <li>Flash their high-beam lights in the eyes of approaching drivers.</li>\
                <li>Talk loudly or use profanity.</li>\
                <li>Resort to crowding and forcing others out of traffic lanes..</li>\
              </ul>\
            </div>\
          </div>\
          <div class="content">\
            <h4>Mental and Physical:</h4>\
            <p>\
              Any driver with signs of mental or emotional disturbances may be unable to cope with a difficult situation, and this makes them an unsafe driver. Such disturbances may cause the driver serious anxiety, tension, aggression, or anger, especially in their interaction with other drivers. To help avoid being an accident:\
            </p>\
            <ul>\
              <li>Have regular medical checkups, and maintain health.</li>\
              <li>Avoid driving in stressful traffic situations.</li>\
              <li>Drive in familiar areas.</li>\
              <li>Keep farther distances from the cars ahead.</li>\
              <li>Concentrate on the whole traffic scene.</li>\
              <li>Don"\'"t drive when emotionally upset or angry, worried, or daydreaming.</li>\
              <li>Avoid prolonged hours of driving.</li>\
              <li>Do not drive if you are tired or sleepy.</li>\
            </ul>\
          </div>\
          <div class="content">\
            <h4>Avoiding Fatigue:</h4>\
            <p>\
            Use techniques for avoiding drowsiness for a short time like stopping often, getting fresh air,  or singing. If you find you have heavy eyes, are yawning, and daydreaming while driving, these are warning signs of fatigue, and it is best to is to pull off the road and get some rest.\
            </p>\
            <div class="text-center">\
              <img src='+ img5 + ' />\
            </div>\
          </div>\
          <div class="content">\
            <h4>When You Should Ask for Help:</h4>\
            <div class="row">\
              <div class="col-md-2">\
                <img class="width-100" src='+ img6 + ' />\
              </div>\
              <div class="col-md-8">\
              <p>\
                Drinking while driving is another physical enemy on the road. Set up a designated driver if you are going to be drinking alcohol; don’t ever take a chance with lives. \
              </p>\
              <p>\
              If You Drink – Don’t Drive!\
              </p>\
              </div>\
              <div class="col-md-2">\
                <img class="width-100" src='+ img7 + ' />\
              </div>\
            </div>\
          </div>\
          <div class="content">\
            <p>\
            If you have a broken limb, or other ailment that can keep you from making quick movements and judgments while on the road, or if you have a physical problem or medical condition that impairs your ability to safely operate a vehicle, get a friend or relative to drive you. \
            </p>\
          </div>\
        </div>'
      },
      {
        index: "Planning Ahead",
        text: '<div class="container"> \
          <h2>Planning Ahead</h2> \
          <hr/> \
          <div class="focus content"> \
            <p> \
            Planning ahead can prevent many serious driving challenges, even for routine local trips. Simple preparations such as checking weather and road conditions before traveling can reduce potential hazards, and save time. You should also have the location’s address and have the route you plan to take mapped out ahead of time. Set up your GPS or review a map before you start driving. \
            </p> \
            <div class="text-center"> \
              <p>If you miss a street, do not try to make a split-second turn or attempt to stop, as this is very hazardous. Go around the block and try again. If traveling on the freeway and you pass your exit, do not try to stop, cut-off, or endanger other cars with unsafe maneuvers. Just continue on to the next exit and then back track.</p> \
              <img class="pull-center" src='+ img12 + ' /> \
            </div> \
          </div> \
          <div class="focus content"> \
            <h4>Planning Your Route:</h4> \
            <p> \
            It’s easy to get lost when traveling in a new area. If you get lost trying to find your destination, stop and ask for directions. Although it may seem inconvenient to do, stopping to ask someone where to go can save you time in the long run. Asking someone will also help you avoid potential hazards that can be caused from driving around searching for your route. \
            </p> \
            <p>Drivers need to know where they are going and how they are going to get to their destination. Whether you are driving down the street to the store, or driving across the state to visit family, always have a plan for how you will get there prepared ahead of time. Consult your map or set up your GPS before you start driving. Being prepared will save you time and reduce potential dangers from driving for a longer amount of time than is necessary. </p> \
            <div class="text-center"> \
              <img class="pull-center" src='+ img14 + ' /> \
            </div> \
            <ul> \
              <li>Looking for items on the floor of the car.</li> \
              <li>Checking and adjusting your GPS.</li> \
              <li>Changing radio stations.</li> \
              <li>Adjusting your temperature controls.</li> \
              <li>Taking in the view.</li> \
              <li>Doing your makeup, shaving or brushing your teeth.</li> \
            </ul> \
          </div> \
          </div>'
      },
      {
        index: "Avoiding Hazards",
        text: '<div class="container"> \
          <div class="focus content"> \
            <h2>Avoiding Hazards</h2> \
            <p> \
            There are many type of driving hazards you may face on any given day.   Understanding what these are before you get behind the wheel can help prevent accidents and injuries while driving. You should always be prepared to take certain steps to identify and avoid potential dangers. In this section, we will examine some techniques that will help you to maintain your safety when driving and avoid potential hazards. \
            </p> \
            <div class="text-center"> \
              <img class="pull-center" src='+ img14 + ' /> \
            </div> \
            <h4>Maintaining a Safe Following Distance:</h4> \
            <p> \
              Most rear-end collisions are caused by drivers following too closely. Although you may feel in control of your vehicle, you cannot control sudden braking by the car in front on you. You should always maintain a safe following distance behind other vehicles. \
            </p> \
            <p> \
              Create the proper distance between you and the vehicle ahead by using the "three-second-rule." When the vehicle ahead of you passes a reference point, such as a sign, count "one-thousand-one, one-thousand-two, one-thousand-three." If you pass the reference point before you finish counting, you are following too close. \
            </p> \
            <div class="text-center"> \
              <img class="pull-center" src='+ img16 + ' /> \
            </div> \
            <h5>You Should Allow A Four-Second Cushion When:</h5> \
            <ul> \
              <li>Being crowded by a tailgater.</li> \
              <li>Driving on slippery roads, and when foggy or raining, and hard to see in weather conditions.</li> \
              <li>Following motorcyclists on wet or icy roads, on metal surfaces, or on gravel. Motorcyclists can fall more easily on these surfaces.</li> \
              <li>The driver behind you wants to pass. Allow room in front of your vehicle so the driver will have space to move in front of you.</li> \
              <li>Towing a trailer or carrying a heavy load. The extra weight makes it harder to stop.</li> \
              <li>Following large vehicles that block your view ahead. The extra space allows you to see around the vehicle.</li> \
              <li>You see a bus, school bus, or a placarded vehicle at railroad crossings. These vehicles must stop at railroad crossings, so slow down early and allow plenty of room.</li> \
              <li>Merging onto a freeway.</li> \
            </ul> \
            <div class="text-center"> \
              <img class="pull-center" src='+ img17 + ' /> \
            </div> \
          </div> \
          <div class="focus content"> \
            <p> \
             <b>Manual</b> distractions cause you to take <b>one or both hands off of the wheel. </b> \
            </p> \
            <span> \
              Examples include: \
            </span> \
            <ul> \
              <li>Eating and drinking.</li> \
              <li>Adjusting your child"\'"s seatbelt.</li> \
              <li>Smoking.</li> \
              <li>Searching through your purse or wallet.</li> \
              <li>Turning knobs in your car.</li> \
            </ul> \
            <div class="text-center"> \
              <img class="pull-center" src='+ img17 + ' /> \
            </div> \
          </div> \
          <div class="focus content"> \
            <p> \
              At faster speeds, the distance you travel will be greater, but the "\'"count"\'" stays the same. At 35 mph, you will travel 50 feet per second, so three seconds would be about 150 feet. At 55 mph you will travel 80-feet-per second, so three seconds will be about 240 feet. \
            </p> \
            <p> \
              Increase this following distance to four seconds when driving in adverse conditions, when following a potentially drunk driver, when following a truck or motorcycle, or when you encounter any unusual situations. \
            </p> \
            <div class="text-center"> \
              <iframe width="560" height="315" src="https://www.youtube.com/embed/E1-zopaeIu0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> \
            </div> \
            <p> \
            Never stop your vehicle on a roadway and block the normal movement of traffic when you are attempting to increase your following distance, unless it is necessary for safe operation, or in obedience to a law, or emergency situation. \
            </p> \
          </div> \
          <div class="focus content"> \
            <h4 class="text-center">Allowing More Space Behind Motorcycles:</h4> \
            <p> \
            When driving near a motorcycle, you should respect the motorcyclist as they have all the same privileges of any vehicle on the road. Also, keep in mind that motorcycles fall more often on wet or icy roads, on metal surfaces, such as bridge gratings or railroad tracks and on gravel. As a result, you should give the motorcyclist the full lane of travel and increase your following distance behind them.   \
            </p> \
            <div class="text-center"> \
              <img class="pull-center" src='+ img18 + ' /> \
            </div> \
            <h4 class="text-center">As a Safe Driver, You Have the Ability to Help Protect Their Safety by Following These Tips:</h4> \
            <ul> \
              <li> \
                Signal and execute your maneuvers well in advance to communicate your intentions to the motorcycle rider. \
              </li> \
              <li> \
                Watch for motorcycles on the highway, at intersections, and when they make left turns or lane changes. \
              </li> \
              <li> \
                Allow enough room for motorcyclists to take evasive actions. \
              </li> \
              <li> \
                Anticipate a motorcyclist"\'"s maneuvers, such as traveling between lanes of traffic. \
              </li> \
            </ul> \
          </div> \
          <div class="clearfix">&nbsp;</div> \
          <div class="content">  \
            <h4>Avoid Driving in Blind Spots of Other Vehicles:</h4> \
            <p> \
            The space to each side of your car is very important. While you cannot control another vehicle, you can control where you are in relation to other vehicles. Never place your car in a position where the other driver cannot see you in his mirrors or drive in the other driver"\'"s blind spot. \
            </p> \
            <div class="text-center"> \
              <img class="pull-center" src='+ img19 + ' /> \
            </div> \
            <p>  \
            When driving on the roadway with large trucks and large recreational vehicles or buses, you should keep in mind that if you cannot see the driver in their mirror, they cannot see you.  \
            </p> \
          </div> \
          <div class="content"> \
            <h4>Tips to consider when sharing the road with trucks and large vehicles:</h4> \
            <div> \
              <ul> \
                <li> \
                  Do not travel in a truck"\'s" blind spot. This means you should avoid driving along the right or left of the truck, and directly behind it. \
                </li> \
                <li> \
                  The blind spot on the right is particularly large. A truck driver will probably not be able to see you at all if you drive near the right front tire. \
                </li> \
              </ul> \
              <div class="text-center"> \
                <img src='+ img20 + ' /> \
              </div> \
            </div> \
            <p> \
              If you plan to pass a large truck, you should start your pass farther back than you would when passing a car. You will need the extra room to complete the pass because of the truck"\'s" length. Also, be sure you have a clear path in the road ahead, with plenty of space to spare. Keep as much distance from a truck as possible when one is passing you, or you are passing the truck. \
            </p> \
            <p> \
              Large vehicles have a harder time braking on a slippery surface due to their size. Also keep in mind that your vision may be impaired by spray from a passing truck, if there is standing water on the road. For this reason, you should maintain as much distance from the truck as possible, while staying in your lane. \
            </p> \
            <p> \
              If you are traveling down a mountain road or long downgrade, and a truck comes up behind you at a high speed, get out of the way as soon as possible. They truck may have lost its brakes and could potentially collide with you if you do not move. \
            </p> \
            <div class="text-center"> \
                <img src='+ img21 + ' /> \
            </div> \
            <p> \
              When driving next to a large truck, always keep in mind that due to their size, they will need to make a wide turn when making turns. When a large truck signals to make a right turn, they might start moving to the left. Avoid driving close to their right side and stay out of their way. If you are not careful, you may collide with their trailer if you get caught between the truck and the curb. \
            </p> \
          </div> \
          <div class="content"> \
            <h4>Planning Evasive Actions:</h4> \
            <p> \
              To be a defensive driver, you must manage the space between yourself and other drivers. This will ensure you have room to make changes in maneuvering, and to react to changes in traffic around you.  You should look far ahead of you, and behind you, and plan lane changes and turns well ahead of time. \
            </p> \
            <div class="text-center"> \
              <img src='+ img22 + ' /> \
            </div> \
            <p> \
              To ensure that you have enough time to react to changes in the traffic ahead, use the three-second-rule for following distance. You can help prevent collisions by keeping your eyes moving, continually scanning your driving environment. \
            </p> \
          </div> \
          <div class="content"> \
            <h4>Elements to Consider:</h4> \
            <p> \
            Things often found on or near the road can also limit your vision. When vision is limited, be prepared, and ready to stop. Objects of almost any size may hide hazards from your view. Some examples include: \
            </p> \
            <div class="row"> \
              <div class="col-md-8"> \
                <ul> \
                  <li> \
                    A tree, shrubbery or truck could hide a pedestrian. \
                  </li> \
                  <li> \
                    A collision up ahead or other collision potentials. \
                  </li> \
                  <li> \
                    Signboards or buildings can also block a large vehicle or other hazards. \
                  </li> \
                </ul> \
              </div> \
              <div class="col-md-2"> \
                <img class="width-100" src='+ img23 + ' /> \
              </div> \
            </div> \
          </div> \
          <div class="content"> \
            <h4>When You Should Ask for Help:</h4> \
            <div class="row"> \
              <div class="col-md-2"> \
                <img class="width-100" src='+ img6 + ' /> \
              </div> \
              <div class="col-md-8"> \
              <p> \
                Drinking while driving is another physical enemy on the road. Set up a designated driver if you are going to be drinking alcohol; don’t ever take a chance with lives. \
              </p> \
              <p> \
              If You Drink – Don’t Drive! \
              </p> \
              </div> \
              <div class="col-md-2"> \
                <img class="width-100" src='+ img7 + ' /> \
              </div> \
            </div> \
            <p> \
              Search the road for hazards that might cause a problem as you drive, and plan any actions so you can avoid a collision with cars around you. Always check your mirrors and look over your shoulder when making any lane changes. Watch the road and the vehicles around you.  Take into consideration the conditions of the road and environmental hazards, so that you are prepared to respond quickly and safely. \
            </p> \
          </div> \
          <div class="content"> \
            <h4>Daytime Headlight Usage:</h4> \
            <p> \
              It is the law that you must turn on your low- beam headlights whenever you are using your windshield wipers. This increases the visibility of your vehicle to other drivers. Even on clear and sunny days, driving with your low-beam headlights on increases your vehicle’s visibility. \
            </p> \
            <p> \
              Driving with headlights on during the day reduces your chance of collision by about 25%, because other drivers can see you from at least 1/2 mile ahead. \
            </p> \
            <div class="text-center"> \
              <img src='+ img24 + ' /> \
            </div> \
          </div> \
          <div class="content"> \
            <h4>Avoiding Lane Changes In or Near Intersections:</h4> \
            <p> \
            Although it is not a violation, changing lanes within or near an intersection is a dangerous maneuver. Other vehicles may be preparing to make a turn and can get confused if you change lanes in an intersection. \
            </p> \
            <p> \
              Driving with headlights on during the day reduces your chance of collision by about 25%, because other drivers can see you from at least 1/2 mile ahead. \
            </p> \
            <div class="text-center"> \
              <img src='+ img24 + ' /> \
            </div> \
          </div> \
          <div class="content"> \
            <h4>Daytime Headlight Usage:</h4> \
            <p> \
              It is the law that you must turn on your low- beam headlights whenever you are using your windshield wipers. This increases the visibility of your vehicle to other drivers. Even on clear and sunny days, driving with your low-beam headlights on increases your vehicle’s visibility.  \
            </p> \
            <div class="text-center"> \
              <img src='+ img24 + ' /> \
            </div> \
          </div> \
          <div class="content"> \
            <h4>Visual Habits:</h4> \
            <p> \
            You should always be moving your eyes when your vehicle is in motion. Check your side and rear view mirrors every few seconds, so that you are constantly aware of the position of the vehicles around you.  As you look ahead, you will be able to spot hazards that may be in your path more easily.  \
            </p> \
            <div class="text-center"> \
              <img src='+ img24 + ' /> \
            </div> \
            <p> \
            It is critical that you are able to react decisively and safely to execute your escape route if a hazard, an object, or an animal suddenly blocked your path. You can quickly decide what your alternative path will be by looking ahead. That is why it is extremely important that you are always looking down the road 10 to 15 seconds ahead, so that you can allow yourself extra space and time to make immediate decisions. \
            </p> \
          </div> \
          <div class="content"> \
            <h4>Create a Space Cushion:</h4> \
            <p> \
            You need time to react when another driver makes a mistake. Allow yourself this time by keeping a "space cushion". The space cushion is the area all around your vehicle. \
            </p> \
            <p> \
            Be alert to vehicles on either side, to the front of, and to the rear of your car. Check other traffic lanes by using your mirrors and by periodically turning your head to look quickly to the side, checking over your shoulder. \
            </p> \
            <div class="text-center"> \
              <img src='+ img24 + ' /> \
            </div> \
            <p> \
            Leave enough space between you and the vehicle ahead of you so you can safely stop if needed. It is always best to maintain three to four seconds distance between you, and the car in front of you. \
            </p> \
            <p> \
            Always check your mirrors for vehicles that may be ready to pass you before changing lanes. You should also turn your head and check beside your vehicle, by looking over your shoulder. Signal to any other vehicle that may be affected by your lane change.  \
            </p> \
          </div> \
          <div class="content"> \
            <h4>Other Drivers:</h4> \
            <p> \
            Good driving habits benefit everyone on the roadway.  Other good drivers may even help keep you from making a mistake when you are driving. Good drivers help set and maintain the driving standards that all drivers should imitate. \
Other drivers’ bad driving habits can cause hazards on the roadway. They force you to use your skills as a defensive driver.  Drivers of other vehicles may not be as skilled or as focused as you are.  You must watch for: \
            </p> \
            <ul> \
              <li>Other drivers making unsafe turns in front of you.</li> \
              <li>Other drivers not seeing your vehicle when they are making a lane change.</li> \
              <li>Other drivers neglecting traffic signs or signals.</li> \
              <li>Other drivers not yielding to vehicles or hazards.</li> \
            </ul> \
            <p> \
            Poor driving skills affect your safety. That is why it is imperative to be aware of the environment around you.  Watching traffic, and knowing how to react properly, can help prevent an accident with another vehicle. \
            </p> \
          </div> \
        </div>'
      },
      {
        index: "Right-of-Way",
        text: '<div class="container"> \
          <div class="focus content"> \
            <h2>Right-of-Way</h2> \
            <hr/> \
            <p> \
            Right-of-way rules establish traffic priorities by indicating who shall yield when there is a potential conflict between vehicles, or between a vehicle and a pedestrian. Right-of-way rules require one person to yield and stop, letting the other person proceed first. It is extremely important that drivers know and obey the right-of-way laws. Not knowing or disobeying the laws can lead to fines, and serious accidents. \
            </p> \
            <h4>Right of Way Violations:</h4> \
            <ul> \
              <li>Yield Right-of-Way Signs.</li> \
              <li> \
              Uncontrolled Intersections \
              </li> \
              <li> \
              Left Turn-Right Turn \
              </li> \
              <li> \
              Approaching Intersection Entrance  \
              </li> \
              <li> \
              Yield Right-of-Way  \
              </li> \
              <li> \
              Entry onto Highway  \
              </li> \
              <li> \
              Authorized Emergency Vehicles  \
              </li> \
              <li> \
              Right-of-Way at Crosswalks \
              </li> \
              <li> \
              Pedestrians outside Crosswalks  \
              </li> \
              <li> \
              Starting parked vehicles or backing  \
              </li> \
            </ul> \
          </div> \
          <div class="focus content"> \
            <h4>Pedestrians:</h4> \
            <p> \
            According to the California Vehicle Code, a motor vehicle driver must yield the right-of-way to pedestrians crossing in the roadway within any marked or unmarked crosswalk, at an intersection. When approaching a pedestrian, the driver must slow down and take every precaution to avoid causing harm to the pedestrian. (CVC 21950) \
            </p> \
            <h4>A pedestrian is a person:</h4> \
            <ul> \
              <li>On foot.</li> \
              <li> \
              With a disability that is using a wheelchair, tricycle, or quadricycle. \
              </li> \
            </ul> \
          </div> \
          <div class="focus content"> \
            <h4>When Another Vehicle Stops at Crosswalk:</h4> \
            <p> \
            Whenever a vehicle has stopped at a marked or unmarked crosswalk at an intersection for the purpose of letting a pedestrian cross the roadway, another car may not pass the stopped vehicle. All vehicles must respect the pedestrian’s right-of-way to cross at crosswalks. (CVC 21951) \
            </p> \
            <h4>Intersection without STOP or YIELD Signs: </h4> \
            <ul> \
              <li>Yield to vehicles already in the intersection, and vehicles that arrive at the intersection before you.</li> \
              <li> \
              If you arrive at an intersection at the same time as another driver, you must yield to the car on the right. \
              </li> \
            </ul> \
            <h4>Four-way Stop Intersection:</h4> \
            <ul> \
              <li> \
              Yield to drivers at the intersection who have arrived before you. \
              </li> \
              <li> \
              When two vehicles arrive at a four-way stop simultaneously, the vehicle on the right should have the right of way. \
              </li> \
            </ul> \
            <h4>Three-way Intersection (T-junction):</h4> \
            <ul> \
              <li> \
              Drivers traveling on the through road have the right of way. \
              </li> \
              <li> \
              You must yield to drivers on the through road if you are approaching from the road that ends. \
              </li> \
            </ul> \
            <h4>Railroad Crossings:</h4> \
            <p>Railroad crossings can be deadly if you do not approach and cross them with care. </p> \
            <ul> \
              <li> \
              When you approach a railroad crossing, be sure you can clear the tracks safely before and after proceeding through it. \
              </li> \
              <li> \
              If during 100 feet of approaching the crossing you don’t have a clear and unobstructed view of the crossing and of any traffic on the railway for a distance of 400 feet in both directions along the railway, you can travel at 15 mph until you are sure the crossing is safe. \
              </li> \
            </ul> \
            <h4>Authorized Emergency Vehicles:</h4> \
            <p> \
            Emergency vehicles have the ultimate right-of-way. This means they may speed, and run red lights or stops signs. Serious accidents have been caused by drivers unaware of an emergency vehicle performing these maneuvers.  Any vehicle that has a siren and at least one red light is considered an emergency vehicle.  \
            </p> \
            <ul> \
              <li> \
              If you encounter an emergency vehicle with its lights or sirens engaged within 1000 feet of your vehicle, you must pull over to the far right side of the roadway or curb, unless otherwise directed by a traffic officer.  \
              </li> \
              <li> \
              If you are in an intersection, you must clear it first and the pull to the right. Stay put until the emergency vehicle has cleared your path. \
              </li> \
            </ul> \
            <div class="text-center"> \
              <img class="pull-center" src='+ img27 + ' /> \
            </div> \
            <p> \
              The purpose of this rule is to clear the way so that ambulances, police cars and fire trucks can get to where they have to go as quickly as possible. Once the emergency vehicle passes, you may not follow within 300 feet. \
            </p> \
          </div> \
          <div class="focus content"> \
            <h4>Move-Over Law</h4> \
            <p> \
              According to CVC 21809, a driver must take certain precautions when approaching one of the following types of vehicles that is stationary on the freeway and flashing their amber warning lights: \
            </p> \
            <ul> \
              <li>A tow truck.</li> \
              <li>A Department of Transportation vehicle.</li> \
              <li>Any type of authorized emergency vehicle, such as a fire truck or police car.</li> \
            </ul> \
            <p> \
            When the driver approaches one of these types of vehicles and they are stationary on the freeway with their amber emergency lights flashing, the driver must approach the stationary vehicle with caution before passing the vehicle. When it is safe and reasonable to do so, the driver should change lanes into a lane that is not immediately adjacent to the stationary vehicle. \
            </p> \
            <div class="text-center "> \
              <img class="width-100" src='+ img28 + ' /> \
            </div> \
            <p> \
            The driver should take care to observe traffic conditions and the laws that may prohibit the driver from changing lanes at that time, before making lane changes. If it is not possible to make a lane change at that time, the driver must slow to a safe speed to accommodate the presence of the stationary vehicle. The penalty for failing to slow down or move over for an emergency vehicle starts at $237 plus a point on your driving record. \
            </p> \
            <p> \
            Drivers passing the above mentioned stationary vehicles may overlook these requirements if the stationary vehicle is not adjacent to the freeway, or if the driver is separated from the stationary vehicle by a protective physical barrier. \
            </p> \
            <div class="text-center"> \
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Y-yxmHiTrjY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> \
            </div> \
          </div> \
        </div>'
      },
      {
        index: "Intersections",
        text: '<div class="container"> \
          <h2>Intersections</h2> \
          <hr/> \
          <div> \
              <p>Driving through intersections is one of the most complex driving situations a motorist&nbsp;encounters. According to the Federal Highway Administration, most collisions occur at intersections.</p> \
              <p><strong>Most</strong>&nbsp;of these&nbsp;<strong>crashes</strong>&nbsp;involve left turns. Nationally, 40 percent of all&nbsp;<strong>crashes</strong>&nbsp;involve&nbsp;<strong>intersections</strong>, the second largest category of accidents, led only by rear end&nbsp;<strong>collisions</strong>. Fifty percent of serious&nbsp;<strong>collisions happen</strong>&nbsp;in&nbsp;<strong>intersections</strong>&nbsp;and some 20 percent of fatal&nbsp;<strong>collisions occur</strong>&nbsp;there. (Source: <u><a href="https://mcs.nhtsa.gov/">https://mcs.nhtsa.gov</a></u>)</p> \
              <p>As a result, it is important that drivers approach intersections with caution, carefully observing the laws that govern their usage.</p>\
              <p>&nbsp;</p>\
              <p><em>The California Drivers Handbook defines an intersection as any place where one line of roadway meets another roadway. Intersections include cross streets, alleys, freeway entrances, and any other locations where vehicles travelling on different highways or roads join each other.</em></p>\
              <p align="center"><strong>There are two types of intersections, controlled and uncontrolled.</strong></p>\
              <p><strong>Controlled or Uncontrolled: </strong>Intersections come in two different types; controlled or uncontrolled. A controlled intersection is one that is controlled by street signs, such as stop signs and yield signs. They can also be controlled by traffic signals. An uncontrolled intersection doesn’t have signs or signals to assist you in determining who has the right-of-way.</p>\
              <p align="center"><strong>You must know the appropriate rules to decide who must yield to whom.</strong></p>\
              <div class="text-center">\
                <img src='+ img29 + ' />\
              </div>\
              <p><strong>Signal Controlled Intersections: </strong>A controlled intersection is one where traffic signs or signals are placed to control the flow of traffic. This allows for the safest crossing at an intersection.</p>\
              <p><strong>Always follow these safety steps at controlled intersections</strong>:</p>\
              <p>Check for any traffic control devices, such as lights or signs, and obey them.</p>\
              <p>Look left, then right, and then left again for cars in the cross street before entering the intersection.</p>\
              <p>Check for pedestrians and make sure they see you.</p>\
              <p>Watch oncoming traffic.</p>\
              <p>Watch for vehicles making legal or illegal left or U-turns.</p>\
              <p>Watch for a light that may turn yellow.</p>\
              <p><strong>You have to decide when it is safe to stop on a yellow light: </strong>You need to make certain that you consistently identify the point at which a safe stop can be made. If a vehicle to your rear is tailgating or is too close, they may likely rear-end your vehicle if you stop for the yellow light. If you determine that it is unsafe to stop and best to drive through the intersection on a yellow light, then you must also watch for vehicles that have entered into the intersection from other directions.</p>\
              <p><strong>Uncontrolled Intersections: </strong>At an intersection without signals, or a “Stop” or “Yield” sign, you must slow down and be prepared to stop. Yield the right-of-way to pedestrians, bicyclists, and/or traffic already in the intersection, or just entering it. If you meet at an intersection with another vehicle or bicyclist at the same time, the vehicle to your right has the right-of-way.</p>\
              <p>If you are at a "T" intersection without a “Stop” or “Yield” sign, the traffic on the through road has the right-of-way.</p>\
              <p>If you need to make a legal left turn or a U-turn, yield the right-of-way to approaching traffic that is close enough to be dangerous. Be aware of vulnerable roadway users such as motorcycles, bicyclists, and pedestrians who may be crossing the road, before making turns.</p>\
              <p><strong>Blind Intersections: </strong>Be careful of blind intersections. Vehicles, trees, bushes, or buildings at intersections can block your view of cars coming from the side.</p>\
              <p>&nbsp;</p>\
              <p>When approaching an intersection and you cannot see traffic on the cross street for at least 100 feet in each direction, during the last 100 feet before entering the intersection, <u>you must slow to 15 mph</u>.</p>\
              <div class="text-center">\
                <img src='+ img30 + ' />\
              </div>\
              <p>Look for approaching cars on side streets, as well as pedestrians and cross traffic. Check all oncoming traffic by looking left, then right, and then left again. Only cross the intersection if the road is clear.</p>\
              <p><strong>Speed: </strong>All intersections must be approached with caution. The speed at which you approach an intersection varies depending on factors such as traffic, width of the road, pedestrians, bicycles and motorcycles, street markings, and weather conditions. You must not enter or cross an intersection faster than what is considered to be a reasonable safe speed.</p>\
              <p>Some intersections are clearly marked with traffic control signs or signals; however many are not. When approaching a green light at any speed, prepare to respond safely, and stop if necessary. Never speed up to cross an intersection when the signal light is yellow. Speeding is against the law and trying to beat a red light is very dangerous.</p>\
              <p align="center"><u>Do not run a caution light if you can make a safe stop without causing a rear end accident.</u></p>\
              <p align="center">&nbsp;</p>\
              <div class="text-center">\
              <iframe width="560" height="315" src="https://www.youtube.com/embed/V4_TCvLmcoY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>\
              </div> \
            </div>\
        </div>'
      },
      {
        index: "Turn Signals",
        text: '<div class="container"> \
          <h2>Turn Signals</h2>\
          <hr/>\
          <div>\
            <p align="justify">Any signal of intention to turn right or left shall be given continuously during the last 100 feet traveled by the vehicle before turning. At higher speeds and if a following vehicle is traveling too closely, the driver shall increase their signaling distance to an additional five seconds before the turn or lane change.</p>\
            <div class="text-center">\
              <img src='+ img31 + ' />\
            </div>\
            <p><span><span>For safety, and the beneﬁt of other drivers, you should always signal when you change directions. This gives them time to react safely to your changes. </span></span><span><span><strong>You must use your turn signals before you:</strong></span></span></p>\
            <ul style={{listStyleType: "circle"}}>\
              <li><span >Parallel Park.</span></li>\
              <li><span >Make a turnabout (turnaround).</span></li>\
              <li><span >Change lanes or pass another vehicle.</span></li>\
              <li><span >Turn at an intersection.</span></li>\
              <li><span >Enter or leave a freeway or interstate highway.</span></li>\
              <li><span >Pull away from the curb.</span></li>\
              <li><span >Pull over to the side of the road. If you do not signal, other drivers will not know what you plan to do. They may start to do something that will lead to a collision. </span></li>\
              <li><span >Signal every time you change direction, even when you do not see anyone else around. </span></li>\
              <li><span >The vehicle you do not see is the most dangerous. </span></li>\
              <li><span >Check your blind spots by looking over your shoulder.</span></li>\
            </ul>\
            <p><span ><strong>Signaling When You Slow Down or Stop Unexpectedly:</strong></span></p>\
            <div class="row">\
              <div class="col-md-2">\
              <img class="width-100" src='+ img32 + ' />\
              </div>\
              <div class="col-md-10">\
               <p><span ><span ><span >Your brake lights let people know that you are slowing down, but they do not show how much distance you need to stop. If you are going to stop or slow down at a place where another driver does not expect it, tap your brake pedal as a warning. </span></span></span></p>\
              </div>\
            </div>\
            <div class="text-center">\
            <iframe width="560" height="315" src="https://www.youtube.com/embed/XsuPzOAs_t0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>\
            </div>\
          </div>\
        </div>'
      },
      {
        index: "Signs, Signals",
        text: '<div class="container"> \
          <h2>Signs, Signals and Markings</h2>\
          <hr/>\
          <div> \
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>Traffic Signs and Signals </strong>\
                    </span>\
                  </span>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      help control the flow of traffic. They communicate pertinent information\
                      that drivers must observe and follow to protect their safety, and the\
                      safety of pedestrians, bicyclists, and other roadway users. You may\
                      receive a citation if you fail to observe certain signs and signals.\
                      Additionally, you may also cause a disruption to the flow of traffic, or\
                      worse yet, cause an accident.\
                    </span>\
                  </span>\
                </p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>Traffic Signs: </strong>\
                    </span>\
                  </span>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <br /> The various colors, shapes, symbols, and written messages on the\
                      signs serve to quickly convey information to drivers. By familiarizing\
                      yourself with the types of information communicated by the sign,\
                      depending on their color or shape, you will be able to quickly identify\
                      the action you must take when driving.\
                    </span>\
                  </span>\
                </p>\
                <p>&nbsp;</p>\
                <div class="text-center">\
                  <img class = "width-100" src='+ img38 + ' />\
                </div>\
                <p align="center">\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>\
                        The background color of the signs help to identify the kind of\
                        information the sign communications.\
                      </strong>\
                    </span>\
                  </span>\
                </p>\
                <p align="center">\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>\
                        The most common colors for signs are red, yellow, green, blue, orange,\
                        white, and brown.\
                      </strong>\
                    </span>\
                  </span>\
                </p>\
                <div class="text-center">\
                  <img class = "width-100" src='+ img39 + ' />\
                </div>\
                <div class="row">\
                  <div class="col-md-10">\
                    <p>\
                      <span style={{ color: "#000000" }}>\
                        <span style={{ fontSize: "medium" }}>\
                          A yield sign alerts drivers that they need to slow down when approaching\
                          an intersection, or come to a stop to allow traffic or pedestrians with\
                          the right-of-way to pass first. When you approach a yield sign, you must\
                          slow so that traffic with the right-of-way does not have to stop to\
                          avoid a collision with your vehicle.\
                        </span>\
                      </span>\
                    </p>\
                  </div>\
                  <div class="col-md-2">\
                    <img class = "width-100" src='+ img40 + ' />\
                  </div>\
                </div>\
                <div class="text-center">\
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/rdjI-mwwMaE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>\
                </div>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>Traffic Signals: </strong>\
                    </span>\
                  </span>\
                </p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      Traffic signals are used to control the flow of traffic and direct the\
                      right-of-way for drivers, pedestrians, and other roadway users. Traffic\
                      signals are red, yellow, and green, and can be solid or an arrow. If the\
                      light is solid, the signal directs forward moving traffic. If the light\
                      is an arrow, the signal directs turning traffic.\
                    </span>\
                  </span>\
                </p>\
                <div class="row">\
                  <div class="col-md-3">\
                    <img class="width-100" src='+ img42 + ' /> \
                  </div>\
                  <div class="col-md-9">\
                  < img class="width-100" src='+ img41 + ' />\
                  </div>\
                </div>\
                <p>&nbsp;</p>\
                <div class="text-center">\
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/SJjhQyU0Wlw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>\
                </div>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>Intersections Without Traffic Signals: </strong>\
                    </span>\
                  </span>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      If you approach an intersection without an operating traffic signal, you\
                      should come to a complete stop before entering the intersection. After\
                      coming to a stop, allow right-of-way traffic to proceed, then cross the\
                      intersection with caution when it is safe to drive through.\
                    </span>\
                  </span>\
                </p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    If you are entering an intersection from an alleyway or driveway, stop\
                    first and allow right-of-way traffic to proceed first. Once there is an\
                    opening in the cross traffic, you may proceed with caution when it is safe\
                    to do.\
                  </span>\
                </p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>Pedestrian Signals: </strong>\
                    </span>\
                  </span>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      Pedestrian signals communicate when it is lawful and safe for\
                      pedestrians to cross an intersection. Pedestrians may enter the\
                      crosswalk when the signal is flashing a “Walk” sign or symbol of a\
                      walking person in white lights. Pedestrians may not enter the crosswalk\
                      when the signal is flashing a “Don’t Walk” sign or a symbol of a hand in\
                      red lights.\
                    </span>\
                  </span>\
                </p>\
                <div class="text-center">\
                  <img  src='+ img43 + ' />\
                </div>\
                <p>&nbsp;</p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>Pavement Markings: </strong>\
                    </span>\
                  </span>\
                </p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      Pavement markings are used to convey important information to drivers\
                      and are made up of various lines and symbols. They help determine which\
                      part of the road a driver may use, where lane changes and passing are\
                      permitted, and to alert drivers of potentially hazardous conditions on\
                      the roadway. Pavement markings are also used to reinforce safety.\
                    </span>\
                  </span>\
                  <span style={{ fontSize: "medium" }}>\
                    <em>\
                      Centerlines have been shown to cut crash frequency by 29% compared to\
                      roads without them.\
                    </em>\
                  </span>\
                  <span style={{ fontSize: "small" }}>(source: </span>\
                  <span style={{ color: "#0000ff" }}>\
                    <u>\
                      <a href="https://www.wisctowns.com/documents/bltn_009_pavement_markings.pdf">\
                        <span style={{ fontSize: "small" }}>\
                          https://www.wisctowns.com/documents/bltn_009_pavement_markings.pdf\
                        </span>\
                      </a>\
                    </u>\
                  </span>\
                  <span style={{ fontSize: "small" }}>)</span>\
                </p>\
                <div class="text-center">\
                  <img class = "width-100" src='+ img44 + ' />\
                </div>\
                <p>&nbsp;</p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      Yellow lines separate traffic flowing in opposite directions.\
                    </span>\
                  </span>\
                </p>\
                <ul>\
                  <li>\
                    <span style={{ color: "#000000" }}>\
                      Solid yellow lines - indicates that passing is prohibited\
                    </span>\
                  </li>\
                  <li>\
                    <span style={{ color: "#000000" }}>\
                      Dashed yellow lines - indicates that passing is allowed\
                    </span>\
                  </li>\
                </ul>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      White lines separate traffic flowing in the same direction.\
                    </span>\
                  </span>\
                </p>\
                <ul>\
                  <li>\
                    <span style={{ color: "#000000" }}>\
                      Double white lines - indicates that lane changes are not allowed\
                    </span>\
                  </li>\
                  <li>\
                    <span style={{ color: "#000000" }}>\
                      Dashed white lines - indicates that lane changes are allowed\
                    </span>\
                  </li>\
                </ul>\
                <div class="text-center">\
                <iframe width="560" height="315" src="https://www.youtube.com/embed/BC4-jYsJ9CQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>\
                </div>\
              </div>\
        </div>'
      },
      {
        index: "Markings",
        text: '<div class="container"> \
          <h2>Signs, Signals and Markings</h2>\
          <hr/>\
          <div> \
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>Traffic Signs and Signals </strong>\
                    </span>\
                  </span>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      help control the flow of traffic. They communicate pertinent information\
                      that drivers must observe and follow to protect their safety, and the\
                      safety of pedestrians, bicyclists, and other roadway users. You may\
                      receive a citation if you fail to observe certain signs and signals.\
                      Additionally, you may also cause a disruption to the flow of traffic, or\
                      worse yet, cause an accident.\
                    </span>\
                  </span>\
                </p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>Traffic Signs: </strong>\
                    </span>\
                  </span>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <br /> The various colors, shapes, symbols, and written messages on the\
                      signs serve to quickly convey information to drivers. By familiarizing\
                      yourself with the types of information communicated by the sign,\
                      depending on their color or shape, you will be able to quickly identify\
                      the action you must take when driving.\
                    </span>\
                  </span>\
                </p>\
                <p>&nbsp;</p>\
                <div class="text-center">\
                  <img class = "width-100" src='+ img38 + ' />\
                </div>\
                <p align="center">\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>\
                        The background color of the signs help to identify the kind of\
                        information the sign communications.\
                      </strong>\
                    </span>\
                  </span>\
                </p>\
                <p align="center">\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>\
                        The most common colors for signs are red, yellow, green, blue, orange,\
                        white, and brown.\
                      </strong>\
                    </span>\
                  </span>\
                </p>\
                <div class="text-center">\
                  <img class = "width-100" src='+ img39 + ' />\
                </div>\
                <div class="row">\
                  <div class="col-md-10">\
                    <p>\
                      <span style={{ color: "#000000" }}>\
                        <span style={{ fontSize: "medium" }}>\
                          A yield sign alerts drivers that they need to slow down when approaching\
                          an intersection, or come to a stop to allow traffic or pedestrians with\
                          the right-of-way to pass first. When you approach a yield sign, you must\
                          slow so that traffic with the right-of-way does not have to stop to\
                          avoid a collision with your vehicle.\
                        </span>\
                      </span>\
                    </p>\
                  </div>\
                  <div class="col-md-2">\
                    <img class = "width-100" src='+ img40 + ' />\
                  </div>\
                </div>\
                <div class="text-center">\
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/rdjI-mwwMaE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>\
                </div>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>Traffic Signals: </strong>\
                    </span>\
                  </span>\
                </p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      Traffic signals are used to control the flow of traffic and direct the\
                      right-of-way for drivers, pedestrians, and other roadway users. Traffic\
                      signals are red, yellow, and green, and can be solid or an arrow. If the\
                      light is solid, the signal directs forward moving traffic. If the light\
                      is an arrow, the signal directs turning traffic.\
                    </span>\
                  </span>\
                </p>\
                <div class="row">\
                  <div class="col-md-3">\
                    <img class="width-100" src='+ img42 + ' /> \
                  </div>\
                  <div class="col-md-9">\
                  < img class="width-100" src='+ img41 + ' />\
                  </div>\
                </div>\
                <p>&nbsp;</p>\
                <div class="text-center">\
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/SJjhQyU0Wlw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>\
                </div>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>Intersections Without Traffic Signals: </strong>\
                    </span>\
                  </span>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      If you approach an intersection without an operating traffic signal, you\
                      should come to a complete stop before entering the intersection. After\
                      coming to a stop, allow right-of-way traffic to proceed, then cross the\
                      intersection with caution when it is safe to drive through.\
                    </span>\
                  </span>\
                </p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    If you are entering an intersection from an alleyway or driveway, stop\
                    first and allow right-of-way traffic to proceed first. Once there is an\
                    opening in the cross traffic, you may proceed with caution when it is safe\
                    to do.\
                  </span>\
                </p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>Pedestrian Signals: </strong>\
                    </span>\
                  </span>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      Pedestrian signals communicate when it is lawful and safe for\
                      pedestrians to cross an intersection. Pedestrians may enter the\
                      crosswalk when the signal is flashing a “Walk” sign or symbol of a\
                      walking person in white lights. Pedestrians may not enter the crosswalk\
                      when the signal is flashing a “Don’t Walk” sign or a symbol of a hand in\
                      red lights.\
                    </span>\
                  </span>\
                </p>\
                <div class="text-center">\
                  <img  src='+ img43 + ' />\
                </div>\
                <p>&nbsp;</p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      <strong>Pavement Markings: </strong>\
                    </span>\
                  </span>\
                </p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      Pavement markings are used to convey important information to drivers\
                      and are made up of various lines and symbols. They help determine which\
                      part of the road a driver may use, where lane changes and passing are\
                      permitted, and to alert drivers of potentially hazardous conditions on\
                      the roadway. Pavement markings are also used to reinforce safety.\
                    </span>\
                  </span>\
                  <span style={{ fontSize: "medium" }}>\
                    <em>\
                      Centerlines have been shown to cut crash frequency by 29% compared to\
                      roads without them.\
                    </em>\
                  </span>\
                  <span style={{ fontSize: "small" }}>(source: </span>\
                  <span style={{ color: "#0000ff" }}>\
                    <u>\
                      <a href="https://www.wisctowns.com/documents/bltn_009_pavement_markings.pdf">\
                        <span style={{ fontSize: "small" }}>\
                          https://www.wisctowns.com/documents/bltn_009_pavement_markings.pdf\
                        </span>\
                      </a>\
                    </u>\
                  </span>\
                  <span style={{ fontSize: "small" }}>)</span>\
                </p>\
                <div class="text-center">\
                  <img class = "width-100" src='+ img44 + ' />\
                </div>\
                <p>&nbsp;</p>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      Yellow lines separate traffic flowing in opposite directions.\
                    </span>\
                  </span>\
                </p>\
                <ul>\
                  <li>\
                    <span style={{ color: "#000000" }}>\
                      Solid yellow lines - indicates that passing is prohibited\
                    </span>\
                  </li>\
                  <li>\
                    <span style={{ color: "#000000" }}>\
                      Dashed yellow lines - indicates that passing is allowed\
                    </span>\
                  </li>\
                </ul>\
                <p>\
                  <span style={{ color: "#000000" }}>\
                    <span style={{ fontSize: "medium" }}>\
                      White lines separate traffic flowing in the same direction.\
                    </span>\
                  </span>\
                </p>\
                <ul>\
                  <li>\
                    <span style={{ color: "#000000" }}>\
                      Double white lines - indicates that lane changes are not allowed\
                    </span>\
                  </li>\
                  <li>\
                    <span style={{ color: "#000000" }}>\
                      Dashed white lines - indicates that lane changes are allowed\
                    </span>\
                  </li>\
                </ul>\
                <div class="text-center">\
                <iframe width="560" height="315" src="https://www.youtube.com/embed/BC4-jYsJ9CQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>\
                </div>\
              </div>\
        </div>'
      },
      {
        index: "Speed Laws",
        text: '<div class="container"> \
          <h2>Signs, Signals and Markings</h2>\
          <hr/>\
          <div> \
          <h6>Prima Facie Speed Limits:</h6> \
<p> \
The term “prima facie”, as used in the California Vehicle Code, is a speed\
limit that applies when no other specific speed limit is posted. For\
example, if you are traveling on a roadway without any posted speed limit\
signs then the speed on that particular roadway is the Prima Facie speed.\
The Terms Prima Facie is a Latin term meaning “at first face” or “at first\
appearance”.&nbsp;California Vehicle Code Section 22352 sets the prima facie\
speed limits in California for&nbsp;two speed limits covering six classes of\
location (California Manual for Setting Speed Limits May 2014). &nbsp;\
</p>\
<ul>\
<li>\
The first speed limit is 15 mph; and it is applicable to uncontrolled\
railway crossings; blind, uncontrolled intersections; and alleyways\
</li>\
<li>\
The second speed limit is 25 mph; and it is applicable to business and\
residential areas without other posted speed limits; school zones, and\
areas immediately around senior centers\
</li>\
</ul>\
<p>\
However, if you do not see any posted speed limit signs and if you are\
traveling on any other type of roadway such as a multi-lane roadway then\
that roadway may be subject to 65 mph and if the roadway has one lane of\
traffic in each direction then the speed may be 55mph. To avoid being pulled\
over for speeding it is a good idea to be aware of the speed limit on the\
roadway. If the speed limit is adjusted down from the 65 mph or 55 mph, then\
there would be posted speed limit signs at the beginning of the speed zone\
and along the roadway to inform drivers of the speed. (source:\
<a href="https://www.2fixyourtrafficticket.com/what-is-prima-facie-speed-limit-in-california.html">\
https://www.2fixyourtrafficticket.com/what-is-prima-facie-speed-limit-in-california.html\
</a>\
)\
</p>\
<p>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  To explain this idea further, consider this example. On a highway, the\
  prima facie speed limit may be posted at 70 mph. Under normal driving\
  conditions, it is safe to drive at a speed of 70 mph. If however, the\
  highway is covered in dense early morning fog, the prima facie speed\
  limit may be as low as 40 mph. In such a situation, a police officer may\
  give you a ticket for driving at the posted speed limit, if he\
  determines that the speed you are traveling at is too dangerous for the\
  current conditions.\
</span>\
</span>\
</p>\
<div class="text-center"> \
<img  src='+ img46 + '/> \
</div>\
<p>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  The prima facie speed limit also serves to designate the maximum speed\
  that you may travel at. Considering again the example above, the maximum\
  speed that you may travel on a highway is 70 mph. All speeds above this\
  rate are considered unsafe, no matter what the conditions are.\
</span>\
</span>\
</p>\
<p>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  <strong>Basic Speed Law:</strong>\
</span>\
</span>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  <br /> California"\'"s "Basic Speed Law" says that you shall not drive\
  faster than is safe for the road, weather and traffic conditions.\
  Consequently, no matter what the speed limit sign says, your speed\
  should depend on:\
</span>\
</span>\
</p>\
<ul>\
<li>\
<span style={{ color: "#000000" }}>\
  The speed of other cars on the road (flow); never drive faster than the\
  other cars around you;\
</span>\
</li>\
<li>\
<span style={{ color: "#000000" }}>\
  Whether the road surface is smooth, rough, graveled, wet, dry, wide, or\
  narrow; \
</span> \
</li> \
<li> \
<span style={{ color: "#000000" }}>\
  Whether any bicyclists or pedestrians are walking on the edge of the\
  roadway;\
</span>\
</li>\
<li>\
<span style={{ color: "#000000" }}>\
  Weather conditions, or Hazards or collisions\
</span>\
</li>\
</ul>\
<p>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  The basic speed law is designed to minimize traffic collisions and\
  traffic congestion. It is not used to drive faster than the advisory\
  speed limits.\
</span>\
</span>\
</p>\
<p>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  One caution, while it is important that you consider the "flow" of\
  traffic when choosing a safe speed, it is never legal to exceed the\
  speed limit of the road to keep up with other traffic.\
</span>\
</span>\
</p>\
<table style={{ height: 25, border: "2px solid" }} width={459}>\
<tbody>\
<tr>\
  <td style={{ width: 449 }}>\
    <p>\
      <span style={{ color: "#000000" }}>\
        <span style={{ fontFamily: "Tahoma, serif" }}>\
          <span style={{ fontSize: "small" }}>\
            <strong>Basic Speed Law CVC Section 22350:</strong>\
          </span>\
        </span>\
      </span>\
      <span style={{ color: "#000000" }}>\
        <span style={{ fontFamily: "Tahoma, serif" }}>\
          <span style={{ fontSize: "small" }}>\
            <br /> <br /> No person shall drive a vehicle upon a highway\
            at a speed greater than is reasonable or prudent having due\
            regard for weather, visibility, the traffic on, and the\
            surface and width of, the highway, and in no event at a speed\
            which endangers the safety of persons or property.\
          </span>\
        </span>\
      </span>\
    </p>\
  </td>\
</tr>\
</tbody>\
</table>\
<p>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  <strong>Maximum Speed on a State Highway: </strong>\
</span>\
</span>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  The maximum speed permitted on most highways is 65 mph. On some\
  highways, you may drive as fast as 70 mph if there are signs posted. It\
  is important to keep in mind that this speed is based on normal driving\
  conditions. In instances of severe weather or road construction, the\
  speed limit will be lower.\
</span>\
</span>\
</p>\
<p>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  <strong>\
    Highways Passing Through Residential, and Business Districts:\
  </strong>\
</span>\
</span>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  The speed limit is 25 miles per hour on any highway, other than a state\
  highway, that passes through a business or residential district. An\
  exception to this standard may be applied if a local authority\
  determines the appropriate speed to be otherwise. Additionally, the\
  speed limit is 25 mph on any road that passes through a residential or\
  business district, unless otherwise posted.\
</span>\
</span>\
</p>\
<p>&nbsp;</p>\
<div class="row">\
<div class="col-md-12">\
<img class="pull-right" src='+ img50 + ' />\
</div>\
<div class="col-md-12">\
<p>\
  <span>\
    <span>\
      <strong>Speed Limits on Undivided Highways: </strong>\
    </span>\
  </span>\
  <span>\
    <span>\
      Unless otherwise posted, the maximum speed limit is 55 mph on two-lane\
      undivided highways for vehicles of all types and for vehicles towing\
      trailers.\
    </span>\
  </span>\
</p>\
</div>\
</div>\
<p>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  <strong>Speed Limits in an Alley: </strong>\
</span>\
</span>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  The speed limit while driving through an alley is 15 mph.\
</span>\
</span>\
</p>\
<p>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  <strong>Speed Limits at an Uncontrolled Blind Intersection: </strong>\
</span>\
</span>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  A blind intersection is one where you cannot see cross traffic for 100\
  feet in either direction, during the last 100 feet approaching the\
  intersection. Building, trees, parked cars, or pedestrians can block\
  your view of oncoming vehicles approaching from the sides. At a blind\
  intersection, you must slow your vehicle to 15 mph. If, however, the\
  cross streets are controlled by yield or stop signs, you may drive the\
  posted speed limit.\
</span>\
</span>\
</p>\
<p>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  <strong>Speed Limited at Uncontrolled Railroad Crossings: </strong>\
</span>\
</span>\
<span style={{ color: "#333333" }}>\
<span style={{ fontSize: "medium" }}>\
  When you approach a railroad track within 100 feet, and you cannot see\
  the train from either direction 100 feet, the speed limit is 15 mph.\
</span>\
</span>\
<span style={{ color: "#333333" }}>\
<span style={{ fontFamily: "Helvetica, serif" }}>\
  <span style={{ fontSize: "small" }}>&nbsp;</span>\
</span>\
</span>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  \
  If gates, warning signals, or a flagman control the railroad crossing,\
  then a speed greater than 15 mph is permitted.\
</span>\
</span>\
</p>\
<div class="text-center">\
<img  src='+ img47 + '/>\
</div>\
<p>&nbsp;</p>\
<p>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  <strong>Speed Limits Around Children: </strong>\
</span>\
</span>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  The speed limit is 25 mph when you are driving within 500 to 1000 feet\
  of a school and children are present. This speed limit is applicable,\
  regardless of whether or not the school is surrounded by some perimeter,\
  such as a fence or wall. Some school zones have speed limits as low as\
  15 mph. It is vitally important to pay attention to the posted speed\
  limit signs.\
</span>\
</span>\
</p>\
<div class="text-center">\
<img  src='+ img48 + '/>\
</div>\
<p>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  <strong>Speed Limits Around Senior Citizens: </strong>\
</span>\
</span>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  You are permitted to drive at a speed of 25 mph around a senior center\
  or facility used primarily by senior citizens. You should take care to\
  note where these facilities are located in your community, as they are\
  not required to post a “SENIOR” warning sign on or near their facility.\
  It would be very easy to accidentally exceed the speed limit near such a\
  facility if you are unaware of their location.\
</span>\
</span>\
</p>\
<p>\
<span style={{ color: "#222222" }}>\
<span style={{ fontFamily: "Arial, serif" }}>\
  <span style={{ fontSize: "small" }}>\
    <em>\
      For residential districts, business districts, school zones (within\
      500 feet of a school) and when passing a&nbsp;\
    </em>\
  </span>\
</span>\
</span>\
<span style={{ color: "#222222" }}>\
<span style={{ fontFamily: "Arial, serif" }}>\
  <span style={{ fontSize: "small" }}>\
    <em>\
      <strong>senior center</strong>\
    </em>\
  </span>\
</span>\
</span>\
<span style={{ color: "#222222" }}>\
<span style={{ fontFamily: "Arial, serif" }}>\
  <span style={{ fontSize: "small" }}>\
    <em>,, 25 mph is the prima facie&nbsp;</em>\
  </span>\
</span>\
</span>\
<span style={{ color: "#222222" }}>\
<span style={{ fontFamily: "Arial, serif" }}>\
  <span style={{ fontSize: "small" }}>\
    <em>\
      <strong>speed limit</strong>\
    </em>\
  </span>\
</span>\
</span>\
<span style={{ color: "#222222" }}>\
<span style={{ fontFamily: "Arial, serif" }}>\
  <span style={{ fontSize: "small" }}>\
    <em>&nbsp;established by&nbsp;</em>\
  </span>\
</span>\
</span>\
<span style={{ color: "#222222" }}>\
<span style={{ fontFamily: "Arial, serif" }}>\
  <span style={{ fontSize: "small" }}>\
    <em>\
      <strong>California</strong>\
    </em>\
  </span>\
</span>\
</span>\
<span style={{ color: "#222222" }}>\
<span style={{ fontFamily: "Arial, serif" }}>\
  <span style={{ fontSize: "small" }}>\
    <em>\
      &nbsp;Vehicle Code Section 22352 and is in effect as long as no\
      other&nbsp;\
    </em>\
  </span>\
</span>\
</span>\
<span style={{ color: "#222222" }}>\
<span style={{ fontFamily: "Arial, serif" }}>\
  <span style={{ fontSize: "small" }}>\
    <em>\
      <strong>speed limit</strong>\
    </em>\
  </span>\
</span>\
</span>\
<span style={{ color: "#222222" }}>\
<span style={{ fontFamily: "Arial, serif" }}>\
  <span style={{ fontSize: "small" }}>\
    <em>&nbsp;is posted.</em>\
  </span>\
</span>\
</span>\
</p>\
<div class="text-center">\
<iframe width="560" height="315" src="https://www.youtube.com/embed/QrycG2Qzpy4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>\
</div>\
<p align="center">\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  <strong>Minimum Speed Limits:</strong>\
</span>\
</span>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  <br /> Not only is it illegal to exceed the speed limit, but it is also\
  against the law to drive too slowly. You may not drive so slowly that is\
  slows down or blocks the normal flow of traffic. Also, you may not slow\
  to a stop on a highway and block the flow of traffic, unless it is\
  necessary as a safety precaution.\
</span>\
</span>\
</p>\
<div class="text-center">\
<img  src='+ img49 + '/>\
</div>\
<p>&nbsp;</p>\
<p>\
<span style={{ color: "#000000" }}>\
<span style={{ fontSize: "medium" }}>\
  The Department of Transportation has the right to establish a minimum\
  speed limit on highways when they determine that slow speeds are\
  blocking the normal flow of traffic. Again, if it is necessary as a\
  safety precaution and signs are posted, you may drive slower than the\
  minimum speed limit.\
</span>\
</span>\
</p>\
</div>'
      },
      {
        index: "Changing Lanes and Passing",
        text: '<div class="container"> \
          <h2>Signs, Signals and Markings</h2>\
          <hr/>\
          <div>\
          <p>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                Changing lanes and passing can be very dangerous if it is not attempted\
                correctly. A good tool for completing a safe lane change or pass while\
                driving is the S.M.O.G. model. S.M.O.G. stands for signal, mirror, over\
                the shoulder, and go.\
              </span>\
            </span>\
          </p>\
          <div class="text-center">\
              <img src='+ img51 + ' />\
          </div>\
          <p>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                <strong>Signal: </strong>\
              </span>\
            </span>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                Turn on your signal to let the drivers around know what your intentions\
                are. Keep in mind that you must signal your intention to turn right or\
                left continuously during the last 100 feet traveled by your vehicle\
                before turning.\
              </span>\
            </span>\
          </p>\
          <p>\
            <br />\
          </p>\
          <p>\
            <span style={{ color: "#000000" }}>\
              \
              <span style={{ fontSize: "medium" }}>\
                <strong>Mirror: </strong>\
              </span>\
            </span>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                Use your rear and side view mirrors to determine where other cars are\
                positioned around you.\
              </span>\
            </span>\
          </p>\
          <p>\
            <br />\
          </p>\
          <p>\
            <span style={{ color: "#000000" }}>\
              \
              <span style={{ fontSize: "medium" }}>\
                <strong>Over the Shoulder: </strong>\
              </span>\
            </span>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                Look over your shoulder that corresponds with the direction that you\
                want to move in. For example, if you are planning to move over into the\
                lane to the left of you, you should look over your left shoulder. Always\
                do this to check for blind spots, or vehicles that may be next to you\
                that you cannot see in your side view mirror.\
              </span>\
            </span>\
          </p>\
          <p>\
            <br />\
          </p>\
          <p>\
            <span style={{ color: "#000000" }}>\
              \
              <span style={{ fontSize: "medium" }}>\
                <strong>Go: </strong>\
              </span>\
            </span>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                Once you have completed the steps listed above, and have determined that\
                it is safe to do, complete your pass by moving over into the lane next\
                to you.\
              </span>\
            </span>\
          </p>\
          <p>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                Passing a vehicle can be done safely if drivers correctly judge every\
                one of the following factors before passing:\
              </span>\
            </span>\
          </p>\
          <ul>\
            <li>\
              <span style={{ color: "#000000" }}>\
                The speed of the car you are about to pass.\
              </span>\
            </li>\
            <li>\
              <span style={{ color: "#000000" }}>Your own vehicle’s speed.</span>\
            </li>\
            <li>\
              <span style={{ color: "#000000" }}>\
                The distance both cars will travel before your car can pull safely back\
                into the lane.\
              </span>\
            </li>\
            <li>\
              <span style={{ color: "#000000" }}>\
                The assured clear distance ahead.\
              </span>\
            </li>\
            <li>\
              <span style={{ color: "#000000" }}>\
                The speed and distance of any oncoming car.\
              </span>\
            </li>\
            <li>\
              <span style={{ color: "#000000" }}>\
                The space available to move back in the lane in front of the passed\
                vehicle.\
              </span>\
            </li>\
            <li>\
              <span style={{ color: "#000000" }}>\
                The existence of any additional hazards.\
              </span>\
            </li>\
            <li>\
              <span style={{ color: "#000000" }}>\
                Use of your turn signal to warn the other drivers on the road of your\
                intentions.\
              </span>\
            </li>\
          </ul>\
          <div class="text-center">\
           <iframe width="560" height="315" src="https://www.youtube.com/embed/hG5n9mBko1k" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>\
          </div>\
          <p>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                <strong>When Passing is Authorized:</strong>\
              </span>\
            </span>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                <br /> You may pass on any open road if passing can be made safely, and\
                if the road is clearly marked for two or more lanes of vehicles moving\
                in the same direction of travel. The road may be marked with dashed or\
                solid white lines that separated traffic moving in the same direction.\
                Another clear indication that you may pass is if the road is marked with\
                both a dashed yellow line and a solid yellow line, and the dashed line\
                is closest to the side of the road that you are travelling on.\
              </span>\
            </span>\
          </p>\
          <p>&nbsp;</p>\
          <p>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                <strong>\
                  Passing to the right is only permitted under the following\
                  circumstances:\
                </strong>\
              </span>\
            </span>\
          </p>\
          <p>\
            <span style={{ color: "#000000" }}>\
              When the vehicle being passed is making or about to make a left turn.\
            </span>\
          </p>\
          <p>\
            <span style={{ color: "#000000" }}>\
              On any highway with enough width, that is clearly marked for two or more\
              lanes of traffic moving in the same direction.\
            </span>\
          </p>\
          <p>\
            <span style={{ color: "#000000" }}>On a one-way street</span>\
          </p>\
          <p>\
            <span style={{ color: "#000000" }}>\
              On a highway divided into two roadways, where only one direction of\
              traffic is permitted on each of the roadways.\
            </span>\
          </p>\
          <p>\
            <span style={{ color: "#000000" }}>\
              \
              <span style={{ fontSize: "medium" }}>\
                <strong>When Passing is Prohibited:</strong>\
              </span>\
            </span>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                <br /> There are certain conditions under which passing is prohibited\
                and should not be attempted. You are not allowed to pass if the road is\
                marked by two solid yellow lines. Additionally, never attempt to pass\
                another vehicle when approaching the crest of a hill and you cannot\
                clearly see the oncoming traffic from the other side of the hill. You\
                are not permitted to pass when approaching a blind curve, where your\
                view of the oncoming traffic is obstructed. Lastly, you may not pass if\
                you are within 100 feet of an intersection or railroad crossing, or if\
                your view is blocked by any object within 100 feet.\
              </span>\
            </span> \
          </p>\
          <div class="text-center">\
              <img src='+ img52 + ' />\
          </div>\
          <p>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                <strong>When Being Passed:</strong>\
              </span>\
            </span>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                <br /> Be courteous and don"\'"t speed up when being passed. Stay to the \
                right and allow the other driver to pass you safely.\
              </span>\
            </span>\
          </p>\
          <p>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                As a general rule, if it is not safe, you should not pass another\
                vehicle. If you are going to pass another vehicle and it is allowed by\
                lane markings, these are some things you should think about:\
              </span>\
            </span>\
          </p>\
          <p>&nbsp;</p>\
          <ul>\
            <li>\
              Before you start to pass, be sure you have enough room to safely complete\
              the maneuver.\
            </li>\
            <li>\
              If you have to cut back to your lane too soon, you risk sideswiping the\
              vehicle you are passing.\
            </li>\
            <li>\
              If you do not cut back to your lane soon enough, you risk a head-on\
              collision.\
            </li>\
            <li>\
              When overtaking and passing another vehicle traveling in the same\
              direction on a two-lane road, you should pass only to the left of the\
              vehicle.\
            </li>\
          </ul>\
          <p>\
            <span style={{ color: "#000000" }}>\
              <span style={{ fontSize: "medium" }}>\
                If you are going to pass another vehicle, the entire maneuver must be\
                completed before reaching a no passing zone. In other words, if you are\
                going to pass and there is a no passing zone in 1000 feet, at 55 miles\
                per hour, you would not have enough distance to pass the other vehicle\
                before reaching the no passing zone. Remember that it takes over 1,600\
                feet to complete the pass at that speed.\
              </span>\
            </span>\
          </p>\
        </div>'
      },
      {
        index: "Final Test",
        text: '<div class="container"> \
          <h2>Seminar-1 Test</h2>\
          <hr/>\
          <div>\
            <div class="row">\
              <div class="col-md-12 text-center" >\
                <img src='+ img52jpg + ' />\
              </div>\
              <div>&nbsp;</div>\
              <div class="col-md-12 " >\
                <p class="test-head" >Select the appropriate answer for each question. When you are done, click the button to submit your answers and find out your test score, if you receive a score below 70% you will be able to reread the information you missed. You will be then be required to take a new test until you pass.</p>\
              </div>\
            </div>\
            <div class="row">\
              <div class="col-md-12">\
                <p>1) You can avoid highway hypnosis on an expressway by</p>\
              </div>\
              <div class="col-md-12 option">\
              <div class="custom-control custom-radio">\
              <input type="radio" id="op1" name="q1" class="custom-control-input">\
              <label class="custom-control-label" for="op1">A. talking on your cell phone.</label>\
            </div>\
            <div class="custom-control custom-radio">\
              <input type="radio" id="op2" name="q1" class="custom-control-input">\
              <label class="custom-control-label" for="op2">Or toggle this other custom radio</label>\
            </div>\
           </div>\
            </div>\
          </div>\
        </div>'
      },
    ]
  }
}
