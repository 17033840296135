import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { Content } from "../../actions/Content/GetContent";

export const ContentApi = form => {
  return (dispatch, getState) => {
    dispatch(Content.ContentFetching());
    return _callApi(
      END_POINT.CONTENT.GET_CONTENT.END_POINT,
      END_POINT.CONTENT.GET_CONTENT.METHOD,
      form
    ).then(res => {
      const { message = null, result = null,status=null } = res.data;
      if (!status) {
        dispatch(Content.ContentFetchingFailed({result,message}));
        return res.data;
      } else {
        dispatch(Content.ContentFetched({result}));
        return res.data;
      }
    });
  };
};
