import { ACTIONS } from "../../action-constants/Actions"


const Adding=()=>{
    return {
        type: ACTIONS.COMMON_ACTION.ADD.ADDING,
        payload:{
            isAdding:true,
        },
        isSuccess: false,
    }
}
const AddingFailed=({ result, msg })=>{
    console.log("=========result and msg",result, msg )
    return {
        type: ACTIONS.COMMON_ACTION.ADD.ADDING_FAILED,
        payload:{
            isAdding:false,
            result,
            msg
        },
        isSuccess: false,
    }
}
const Added=({ result })=>{
    return {
        type: ACTIONS.COMMON_ACTION.ADD.ADDED,
        payload:{
            isAdding:false,
            result:result,
        },
        isSuccess: true,
    }
}
export const add = {
    Adding,
    AddingFailed,
    Added
}